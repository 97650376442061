import moment from "moment";
import {
  PostWorkoutInterface,
  SessionDetailInterface,
  SessionQuestion,
  User,
  WorkoutDetailInterface,
  WorkoutInterface,
} from '../../tsUtils';
import * as paths from './paths';
import { getAuthHeader } from './paths';
import { WorkoutPromptQuestions } from "../programs/dialogs/WorkoutDialog";

export async function getWorkout(id: number): Promise<WorkoutDetailInterface[]> {
  let endpoint = `${paths.COACHWORKOUT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function getWorkouts(query: string): Promise<WorkoutDetailInterface[]> {
  let endpoint = `${paths.COACHWORKOUT}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function createWorkout(
  workout: PostWorkoutInterface,
  questions: SessionQuestion[],
  config: {
    day: Day;
    week: number;
    dateTime: string;
  },
): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ workout, questions, config: { ...config } }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function generateWorkout(
  workout: PostWorkoutInterface,
  questions: SessionQuestion[],
  config: {
    day: Day;
    week: number;
    dateTime: string;
  },
  prompt: WorkoutPromptQuestions,
): Promise<WorkoutDetailInterface> {
  const endpoint = paths.GENERATEWORKOUT;
  const headers = getAuthHeader();

  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ workout, questions, config: { ...config }, prompt }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function repeatWorkout(data: {
  day: Day;
  week: number;
  program: number;
  workout: number;
  dateTime: string;
}): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${data.workout}/repeat`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function copyWorkout(workoutId: number): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workoutId}/copy`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateWorkout(
  workout: WorkoutInterface,
  questions: SessionQuestion[],
): Promise<WorkoutDetailInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workout.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ workout, questions }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function renameWorkout(workoutId: number, name: string): Promise<WorkoutInterface> {
  let endpoint = `${paths.COACHWORKOUT}/${workoutId}/rename`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(name),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function deleteWorkout(id: number): Promise<string> {
  let endpoint = `${paths.COACHWORKOUT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "DELETE",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
