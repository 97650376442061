import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import Errored from '@mui/icons-material/Error';
import { removeError } from '../store/app/actions';

// animation to slide saved icon in from bottom then fade out
const savedIcon: any = {
    position: 'fixed',
    bottom: '-75px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    left: '50px',
    zIndex: 1000,
    animation: 'slidein 4s ease-in-out',
    backgroundColor: 'white',
    border: '.5px solid rgb(227, 96, 73)',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    fontSize: '1rem',
    fontWeight: 600,
    color: 'rgb(227, 96, 73)',
    borderRadius: '5px',
    padding: '5px 10px',
    opacity: 0,
};

const Error: React.FC = () => {
    const dispatch = useDispatch();
    const error = useSelector((state: RootState) => state.app.error);
    
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(removeError());
            }, 4000);
        }
    }, [error]);

    return (
        <>
            {error && <div style={savedIcon} ><Errored /> Error!</div>}
        </>
        
    );
};

export default Error;
