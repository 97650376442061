import { ExerciseCardInterface } from '../../tsUtils';
import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { ClientInputsClass, ClientInputsInterface } from '../../tsUtils/clientInputsTypes';
import { selectPreviousSession } from '../sessions/reducers';

export interface ExerciseCardState extends EntityState<ExerciseCardInterface> {
  loading: boolean;
  loaded: Boolean;
  error: any | null;
  currentClient: number;
}

const exerciseCardAdapter = createEntityAdapter<ExerciseCardInterface>({});

export const cardsInitialState: ExerciseCardState = exerciseCardAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  currentClient: 0,
});

export const exerciseCardSlice = createSlice({
  name: 'exerciseCards',
  initialState: cardsInitialState,
  reducers: {
    exerciseCardsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    exerciseCardsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    exerciseCardsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getExerciseCardsSuccess(state, action) {
      exerciseCardAdapter.upsertMany(state, action.payload);
    },
    updateCurrentClient(state, action) {
      state.currentClient = action.payload;
    },
    createExerciseCardSuccess(state, action) {
      exerciseCardAdapter.upsertOne(state, action.payload);
    },
    deleteExerciseCardSuccess(state, action) {
      exerciseCardAdapter.removeOne(state, action.payload);
    },
    updateExerciseCardSuccess(state, action) {
      state.entities = {
        ...state.entities,
        [action.payload.id]: action.payload,
      };
    },
    updateExerciseCardExerciseSuccess(state, action) {
      const card = state.entities[action.payload.cardId];
      if (card) {
        card.exercise = action.payload.exercise;
        exerciseCardAdapter.upsertOne(state, card);
      }
    },
    updateClientInputsSuccess(state, action) {
      const card = state.entities[action.payload.cardId];
      if (card) {
        card.clientinputs = card.clientinputs?.map(inputs =>
          inputs.id === action.payload.inputs.id
            ? (new ClientInputsClass(action.payload.inputs) as ClientInputsInterface)
            : inputs,
        );

        exerciseCardAdapter.upsertOne(state, card);
      }
    },
  },
});
export const selectCardsBySelectedSession = createSelector(
  [
    (state: RootState) => exerciseCardSelectors.selectAll(state),
    (state: RootState) => state.sessions.selectedId,
  ],
  (allCards, sessionId) => {
    if (!!allCards && !!sessionId) {
      return allCards.filter(card => card.session === sessionId);
    }
    return [];
  },
);

export const selectClientInputs = createSelector(
  [
    (state: RootState) => state.exerciseCards.entities,
    (state: RootState, cardId: number, userId: number) => cardId,
    (state: RootState, cardId: number, userId: number) => userId,
  ],
  (allCards, cardId, userId) => {
    return allCards[cardId]?.clientinputs?.find(inputs => inputs.client === userId);
  },
);

export const selectPreviousClientInputs = createSelector([
  (state: RootState) => state.exerciseCards.entities,
  (state: RootState) => selectPreviousSession(state),
  (state: RootState,userId: number, cardIndex: number ) => userId,
  (state: RootState,  userId: number, cardIndex: number) => cardIndex,
], (allCards, previousSession, userId, cardIndex) => {
  if (!previousSession) return null;
  const previousCardId = previousSession.exerciseCards[cardIndex].id;
  if (!previousCardId) return null;
  const previousCard = allCards[previousCardId];
  return previousCard?.clientinputs?.find(inputs => inputs.client === userId) || null;

});

export const {
  exerciseCardsLoading,
  exerciseCardsLoaded,
  exerciseCardsFail,
  getExerciseCardsSuccess,
  createExerciseCardSuccess,
  deleteExerciseCardSuccess,
  updateExerciseCardSuccess,
  updateClientInputsSuccess,
  updateCurrentClient,
} = exerciseCardSlice.actions;

export const exerciseCardSelectors = exerciseCardAdapter.getSelectors<RootState>(
  state => state.exerciseCards,
);
