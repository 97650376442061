import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from '../../../coachApp/services/exercisesAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import * as actionTypes from './actionTypes';
import { exercisesFail, exercisesLoaded, exercisesLoading, getExercisesSuccess } from './reducers';

export function* getExercises(): any {
  try {
    yield put(exercisesLoading());
    const response = yield call(API.getExercises);
    yield put(getExercisesSuccess(response));
    yield put(exercisesLoaded());
  } catch (error) {
    yield put(exercisesFail(error));
  }
}
export function* exerciseSaga() {
  yield takeLatest(actionTypes.GET_EXERCISES, getExercises);
}
