import { Button, Typography } from '@mui/material';
import React from 'react';
type Props = {
  cancel: () => void;
  message: string;
  confirmmessage: any;
  confirmmessage2?: any;
  confirmcolour: string;
  confirm: () => void;
  confirm2?: () => void;
};
const confirmation = {
  width: '400px',
  maxWidth: '95%',
  background: 'white',
};
const message = {
  height: '80%',
  width: '100%',
  padding: '10px',
  alignText: 'center',
};
const options = {
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  padding: '10px',
};

function Confirmation(props: Props) {
  function handleCancel(event: React.SyntheticEvent) {
    event.stopPropagation();
    props.cancel();
  }
  function handleConfirm(event: React.SyntheticEvent) {
    event.stopPropagation();
    props.confirm();
    props.cancel();
  }
  function handleConfirm2(event: React.SyntheticEvent) {
    if (props.confirm2) {
      event.stopPropagation();
      props.confirm2();
      props.cancel();
    }
  }

  return (
    <div style={confirmation}>
      <div style={message}>
        <Typography variant="h5">{props.message}</Typography>
      </div>
      <div style={options}>
        <div style={{ display: 'flex', width: '150px' }}>
          {props.confirm2 && (
            <Button variant="contained" color="error" onClick={event => handleConfirm2(event)}>
              {props.confirmmessage2}
            </Button>
          )}

          <Button variant="contained" color="error" onClick={event => handleConfirm(event)}>
            {props.confirmmessage}
          </Button>
        </div>

        <Button variant="outlined" onClick={event => handleCancel(event)}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default Confirmation;
