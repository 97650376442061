import moment from "moment";
import { User, ExerciseCardInterface, ExerciseCardClass } from '../../tsUtils';
import * as paths from './paths';
import { getAuthHeader } from './paths';

export async function getExerciseCards(query: string): Promise<ExerciseCardInterface[]> {
  let endpoint = `${paths.COACHCARD}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function createExerciseCard(
  exerciseCard: ExerciseCardClass,
): Promise<ExerciseCardInterface> {
  let endpoint = `${paths.COACHCARD}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(exerciseCard),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateExerciseCard(exerciseCard: ExerciseCardInterface): Promise<ExerciseCardInterface> {
  let endpoint = `${paths.COACHCARD}/${exerciseCard.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(exerciseCard),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function deleteExerciseCard(id: number): Promise<string> {
  let endpoint = `${paths.COACHCARD}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "DELETE",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
