// Auth
export const LOGIN = "[Auth] Login";
export const CLIENT_LOGIN = '[Auth] Client Login';
export const AUTH_SUCCESS = "[Auth] Auth Success";
export const LOGIN_FAIL = "[Auth] Login Fail";
export const LOGOUT = "[Auth] Logout";
export const LOGOUT_SUCCESS = "[Auth] Logout Success";
export const CHECK_AUTH_STATE = "[Auth] Check Auth State";
export const CHECK_AUTH_STATE_SUCCESS = "[Auth] Check Auth State Success";
export const CHECK_AUTH_STATE_FAIL = "[Auth] Check Auth State Fail";
export const UPDATE_AUTH_TIMEOUT = "[Auth] Update Auth Timeout";
export const CLEAR_STATE = '[App] Clear State';
// Programs
export const PROGRAMS_START = "PROGRAMS_START";
export const PROGRAMS_SUCCESS = "PROGRAMS_SUCCESS";
export const PROGRAMS_FAIL = "PROGRAMS_FAIL";
export const PROGRAM_RECIEVED = "PROGRAM_RECIEVED";
export const POST_PROGRAM = "POST_PROGRAM";
export const UPDATE_WORKOUT = "UPDATE_WORKOUT";
export const CLEAR_PROGRAM = "CLEAR_PROGRAM";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_DEFINITIONS = "GET_DEFINITIONS";
