export const COACH = "/coachapp/";
export const COACHLOGIN = `${COACH}login/`;
export const COACHLOGOUT = `${COACH}logout/`;
export const COACHPROGRAM = `${COACH}api/program`;
export const COACHWORKOUT = `${COACH}api/workout`;
export const COACHWORKOUTCOPY = `${COACH}api/workout/copy`;
export const COACHSESSION = `${COACH}api/session`;
export const COACHCARD = `${COACH}api/exercisecard`;
export const COACHCLIENT = `${COACH}api/clients`;
export const COACHEXERCISE = `${COACH}api/exercise`;
export const COACHEVENT = `${COACH}api/events`;
export const COACHDEFINITIONS = `${COACH}api/definitions`;
export const COACHEXERCISES = `${COACH}api/exercise`;
export const COACHEXERCISESEARCH = `${COACH}api/exercise/search`;
export const COACHTEMPLATES = `${COACH}api/template`;
export const COACHACCOUNT = `${COACH}api/account_details`;
export const GENERATEPROGRAM = '/ai/generate_program';
export const GENERATEWORKOUT = '/ai/generate_workout';

export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  const authHeaders = new Headers();
  authHeaders.append("Content-Type", "application/json");
  authHeaders.append("Authorization", `token ${token}`);
  authHeaders.append("Accept", "application/json");
  return authHeaders;
};

// const handleErrors = (response: any) => {
//     if (!response.ok) {
//       console.debug(`Response is NOT ok : ${JSON.stringify(response)}`);
//       if (response.status === 401) {
//         console.error(`Session expired`);
//         if (dispatch) dispatch(signOut());
//         else {
//           window.location.pathname = "/login";
//         }
//       } else {
//         //TODO: ensure that statusText is not supposed to be status_text
//         console.error(`Will return error statusText ${response.statusText}`);
//         console.error(`Will return error status_message ${response.status_message}`);
//         throw new Error(response.statusText);
//       }
//     }
//     return response.json();
//   };
