import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { EventBase } from '../../tsUtils/eventTypes';

export interface EventState extends EntityState<EventBase> {
  loading: boolean;
  loaded: Boolean;
  error: any;
}

const eventAdapter = createEntityAdapter<EventBase>({});

export const eventInitialState: EventState = eventAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const eventSlice = createSlice({
  name: 'events',
  initialState: eventInitialState,
  reducers: {
    eventsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    eventsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    eventsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    deleteEventSuccess(state, action) {
      eventAdapter.removeOne(state, action.payload);
    },
    updateEventSuccess(state, action) {
      state.entities = {
        ...state.entities,
        [action.payload.id!]: action.payload,
      };
    },
    getEventsSuccess(state, action) {
      eventAdapter.upsertMany(state, action.payload);
    },
    createEventSuccess(state, action) {
      eventAdapter.upsertOne(state, action.payload);
    },
  },
});

export const {
  eventsLoading,
  eventsLoaded,
  eventsFail,
  getEventsSuccess,
  createEventSuccess,
  updateEventSuccess,
  deleteEventSuccess,
} = eventSlice.actions;

export const eventSelectors = eventAdapter.getSelectors((state: RootState) => state.events);
