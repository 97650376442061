export interface ExerciseBaseInterface {
  id: number;
  name: string;
}
export interface ExerciseInterface extends ExerciseBaseInterface {
  category: string;
  classification: string;
  primary_action: string;
  secondary_action1: string;
  secondary_action2: string;
  primary_muscle: string;
  synergist1: string;
  synergist2: string;
  equipment1: string;
  equipment2: string;
  media: string;
  instructions: string;
}
export interface UserExerciseInterface extends ExerciseInterface {
  creator: number;
}

export enum ExerciseCategory {
  CORE = 'Core',
  GRIP = 'Grip',
  METABOLIC = 'Metabolic',
  LOWER_BODY_PULL = 'Lower Body Pull',
  LOWER_BODY_PUSH = 'Lower Body Push',
  UPPER_BODY_PULL = 'Upper Body Pull',
  UPPER_BODY_VERTICAL_PUSH = 'Upper Body Vertical Push',
  UPPER_BODY_HORIZONTAL_PUSH = 'Upper Body Horizontal Push',
  TOTAL_BODY = 'Total Body',
  OTHER = 'Other',
}