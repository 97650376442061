import { put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { reduceSession } from '../../store/utility';
import * as sessionAPI from '../../coachApp/services/sessionAPI';
import * as workoutAPI from '../../coachApp/services/workoutAPI';
import { getExerciseCardsSuccess } from '../exerciseCards/reducers';
import { updateSessionFeedback } from '../../clientApp/services/clientInputsApi';
import {
  removeProgramConfigSessionSuccess,
  updateProgramConfigSuccess,
} from '../programs/reducers';
import { addSessionSuccess, removeWorkoutSession } from '../workouts/reducers';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  sessionsLoading,
  sessionsFail,
  getSessionsSuccess,
  createSessionSuccess,
  updateSessionSuccess,
  deleteSessionSuccess,
  sessionsLoaded,
  renameSessionSuccess,
  updateSessionFeedbackSuccess,
} from './reducers';
import * as types from './types';

export function* getSessionsSaga({ query }: types.GetSessions) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.getSessions> = yield sessionAPI.getSessions(
      query,
    );
    yield put(getSessionsSuccess(response));
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* createSessionSaga({ session }: types.CreateSessionRequest) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.createSession> =
      yield sessionAPI.createSession(session);
    yield put(createSessionSuccess(response));
    yield put(sessionsLoaded());
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* updateSessionSaga({ session }: types.UpdateSessionRequest) {
  try {
    // const response: SagaReturnType<typeof sessionAPI.updateSession> =
    //   yield sessionAPI.updateSession(session);
    // const { updated, exerciseCards } = reduceSession(response);
    yield put(updateSessionSuccess(session));
    // yield put(getExerciseCardsSuccess(exerciseCards));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* updateSessionFeedbackSaga({ data }: types.UpdateSessionFeedbackRequest) {
  try {
    const response: SagaReturnType<typeof updateSessionFeedback> = yield updateSessionFeedback(
      data,
    );
    yield put(updateSessionFeedbackSuccess(response));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* repeatWorkoutSaga({ data }: types.RepeatWorkoutRequest) {
  try {
    const response: SagaReturnType<typeof sessionAPI.copySession> = yield workoutAPI.repeatWorkout(
      data,
    );
    const { updated, exerciseCards } = reduceSession(response);
    yield put(createSessionSuccess(updated));
    if (exerciseCards) {
      yield put(getExerciseCardsSuccess(exerciseCards));
    }
    yield put(
      updateProgramConfigSuccess({
        day: data.day,
        week: data.week,
        session_config: {
          workoutId: data.workout,
          sessionId: updated.id,
          dateTime: data.dateTime,
        },
      }),
    );
    yield put(addSessionSuccess(updated));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* copySessionSaga({ sessionId }: types.CopySessionRequest) {
  try {
    const response: SagaReturnType<typeof sessionAPI.copySession> = yield sessionAPI.copySession(
      sessionId,
    );
    const { updated, exerciseCards } = reduceSession(response);
    yield put(createSessionSuccess(updated));
    if (exerciseCards) {
      yield put(getExerciseCardsSuccess(exerciseCards));
    }

    yield put(addSessionSuccess(updated));
  } catch (error: any) {
    yield console.log(error);
    yield put(sessionsFail(error));
  }
}
export function* renameSessionSaga({ sessionId, name }: types.RenameSessionRequest) {
  try {
    yield put(sessionsLoading());
    const response: SagaReturnType<typeof sessionAPI.renameSession> =
      yield sessionAPI.renameSession(sessionId, name);
    yield put(renameSessionSuccess(response));
    yield put(sessionsLoaded());
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* deleteSessionSaga({ day, week, config }: types.DeleteSessionRequest) {
  try {
    yield put(sessionsLoading());
    yield console.log({ day, week, config });
    const response: SagaReturnType<typeof sessionAPI.deleteSession> =
      yield sessionAPI.deleteSession(config.sessionId, day, week, config);
    yield put(removeProgramConfigSessionSuccess({ day, week, sessionId: config.sessionId }));
    yield put(deleteSessionSuccess(config.sessionId));
    yield put(removeWorkoutSession({ id: config.sessionId, workoutId: config.workoutId }));
  } catch (error: any) {
    yield put(sessionsFail(error));
  }
}
export function* sessionSaga() {
  yield takeLatest(actionTypes.GET_SESSIONS, getSessionsSaga);
  yield takeLatest(actionTypes.CREATE_SESSION_REQUEST, createSessionSaga);
  yield takeLatest(actionTypes.UPDATE_SESSION_REQUEST, updateSessionSaga);
  yield takeLatest(actionTypes.DELETE_SESSION_REQUEST, deleteSessionSaga);
  yield takeLatest(actionTypes.RENAME_SESSION_REQUEST, renameSessionSaga);
  yield takeLatest(actionTypes.COPY_SESSION_REQUEST, copySessionSaga);
  yield takeLatest(actionTypes.REPEAT_WORKOUT_REQUEST, repeatWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_SESSION_FEEDBACK_REQUEST, updateSessionFeedbackSaga);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}

