import { Typography } from '@mui/material';
import React, { ForwardedRef, useEffect, useState } from 'react';
import { ProgramClass, ProgramDetailInterface } from '../../tsUtils';
import '../../style/Preview/ClientProgram.scss';
import '../../style/Preview/ClientWorkout.scss';
import PreviewProgramHead from '../../common/PreviewProgramHead';
import PreviewWorkout from '../../common/PreviewWorkout';
interface Props {
  program: ProgramDetailInterface;
}
const PrintProgramComponent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [program, setProgram] = useState({
    ...props.program,
    workouts: props.program.workouts.map(workout => {
      return { id: workout.id, order: workout.order, name: workout.name };
    }),
  });
  useEffect(() => {
    if (program.id !== props.program.id) {
      setProgram({
        ...props.program,
        workouts: props.program.workouts.map(workout => {
          return { id: workout.id, order: workout.order, name: workout.name };
        }),
      });
    }
  }, [props.program]);

  return (
    program && (
      <div ref={ref} className="print-program">
        <PreviewProgramHead program={program} type="PROGRAM"></PreviewProgramHead>
        {props.program.workouts.map(workout => (
          <PreviewWorkout key={workout.id} workout={workout} />
        ))}
      </div>
    )
  );
});

export default PrintProgramComponent;
