import React, { useEffect } from 'react';
import '../../../style/Coach/Session.scss';
import { Button } from '@mui/material';
import { AccountType, ExerciseCardClass, SessionInterface } from '../../../tsUtils';
import { RootState } from '../../../store/store';
import ExerciseCardForm from './ExerciseCardForm';
import { selectInputTypes } from '../../../+store/definitions/inputs/reducers';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectCardsBySelectedSession } from '../../../+store/exerciseCards/reducers';
import ClientExerciseCard from '../../../clientApp/program/components/ClientExerciseCard';

type Props = {
  session: SessionInterface;
  workoutId: number;
};

function SessionForm(props: Props) {
  const exerciseCards = useSelector((state: RootState) => selectCardsBySelectedSession(state));
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const inputOptions = useSelector((state: RootState) => selectInputTypes(state));

  const addCard = () => {
    let newCard: ExerciseCardClass;
    if (exerciseCards.length > 0) {
      const card = exerciseCards[exerciseCards.length - 1];

      newCard = new ExerciseCardClass({
        ...card,
        order: card.order + 1,
        session: card.session,
        workout: card.workout,
        inputs: {
          input_types: {
            ...card?.inputs?.input_types,
          },
          sets: card?.inputs?.sets ? [...card?.inputs?.sets] : [],
        },
      });
      delete newCard.id;
      delete newCard.clientinputs;
    } else {
      newCard = new ExerciseCardClass({
        workout: props.workoutId,
        session: props.session.id,
      });
    }

    dispatch(exerciseCardActions.createExerciseCard(newCard));
  };

  const removeCard = (id: number) => dispatch(exerciseCardActions.deleteExerciseCard(id));

  useEffect(() => {}, [props.session]);

  const cards = exerciseCards.map((card, index) => {
    if ( user && (user?.accounttype as AccountType) === AccountType.Client) {
      return <ClientExerciseCard id={card.id!} userId={user.id} key={card.id} index={index} />;
    }
    return (
      <ExerciseCardForm
        inputOptions={inputOptions}
        id={card.id!}
        remove={removeCard}
        card={card}
        key={card.id}
        index={index}
      />
    );
  });

  return (
    <div className="session">
      {cards && <div className="cardsCont">{cards}</div>}
      {user?.accounttype as AccountType !== AccountType.Client && (
        <Button
          variant="outlined"
          sx={{ width: '95%', marginTop: '5px' }}
          onClick={() => addCard()}
        >
          + Exercise
        </Button>
      )}
    </div>
  );
}
export default SessionForm;
