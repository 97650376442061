import { AccountDetails } from '../../tsUtils';
import { AccountDetailsActions } from './types';
import * as actions from './actionTypes'

export interface AccountDetailsState {
  accountDetails: AccountDetails | null;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const accountDetailsInitialState: AccountDetailsState = {
  accountDetails: null,
  loading: false,
  loaded: false,
  error: null,
};

export default function accountReducer(state = accountDetailsInitialState, action: AccountDetailsActions) {
  switch (action.type) {
    case actions.GET_ACCOUNT:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case actions.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accountDetails: action.accountDetails,
      };
    case actions.GET_ACCOUNT_FAIL:
      return {
        ...state,
        loading:false,
        loaded: false,
        error: action.error
      }
    default:
      return state;
  }
}


