import * as actionTypes from './actionTypes';
import { AccountDetails } from '../../tsUtils';

export const GET_ACCOUNT = '[Account] Get Account';
export const GET_ACCOUNT_SUCCESS = '[[Account] Get Account Success';
export const GET_ACCOUNT_FAIL = '[Account] Get Account Failure';

export function getAccount() {
  return {
    type: actionTypes.GET_ACCOUNT,
  };
}
export function getAccountSuccess(accountDetails: AccountDetails) {
  return {
    type: actionTypes.GET_ACCOUNT_SUCCESS,
    accountDetails,
  };
}
export function getAccountFail(error: any) {
  return {
    type: actionTypes.GET_ACCOUNT_FAIL,
    error,
  };
}
