import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Saved from './common/Saved';
import Error from './common/Error';

export interface LayoutProps {
  isMobile: boolean;
}

export default function Layout(props: LayoutProps) {
  const [isMobile, setIsMobile] = React.useState(props.isMobile);
  
  React.useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);
  
  return (
    <div className="App">
      <Outlet />
      <Saved />
      <Error />
    </div>
  );
  
}
