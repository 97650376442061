import { Definition } from '../../../tsUtils';
import * as types from './types';

export const GET_DEFINITIONS = '[Definitions] Get Definitions';
export const GET_DEFINITIONS_SUCCESS = '[Definitions] Get Definitions Success';
export const GET_DEFINITIONS_FAIL = '[Definitions] Get Definitions Failure';

export function getDefinitions() {
  return {
    type: GET_DEFINITIONS,
  };
}

export function getDefinitionsSuccess(definitions: Definition[]) {
  return {
    type: GET_DEFINITIONS_SUCCESS,
    definitions,
  };
}
export function getDefinitionsFail(error: any) {
  return {
    type: GET_DEFINITIONS_FAIL,
    error,
  };
}
