// Rest time countdown timer dialog

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

interface RestTimerProps {
    rest: string;
}
function initiateDateFromTimeString(timeString: string) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    // Create a new Date object with the current date
    const currentDate = new Date();
  
    // Set the hours, minutes, and seconds to the corresponding values from the time string
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);
  
    return currentDate;
  }

export default function RestTimerDialog(props: RestTimerProps) {

    const [open, setOpen] = React.useState(false);
    const [time, setTime] = React.useState({minutes: 0, seconds: 0});
    const [dateFromRest, setDateFromRest] = React.useState<Date>(initiateDateFromTimeString(props.rest));
    
    const tickInterval = React.useRef<any>();

    React.useEffect(() => {
        setDateFromRest(initiateDateFromTimeString(props.rest));
    }, [props.rest]);

    const handleClose = () => {
        setOpen(false);
        clearInterval(tickInterval.current);
    }

    const handleStart = () => {
        const [mins, secs] = [dateFromRest.getMinutes(), dateFromRest.getSeconds()];
        setTime({minutes: mins, seconds: secs});
        setOpen(true);
        const timeSet = new Date();
        let timePast = 0;
        tickInterval.current = setInterval(() => {
            setTime(previous => {
                const timeNow = new Date();
                const timeDiff = timeNow.getTime() - timeSet.getTime();
                const timeDiffSecs = Math.floor(timeDiff / 1000);
                const timeSubstract = timeDiffSecs - timePast;
                timePast = timeDiffSecs;
                if (previous.seconds > 0) {
                    return {minutes: previous.minutes, seconds: previous.seconds - timeSubstract};
                } else if (previous.minutes > 0) {
                    return {minutes: previous.minutes - 1, seconds: 60 - timeSubstract};
                } else {
                    clearInterval(tickInterval.current);
                    return {minutes: 0, seconds: 0};
                }
            });
        }, 1000);
    }
    const handleRestart = () => {
        clearInterval(tickInterval.current);
        handleStart();
    }

    return (
        <div>
            <Button variant="text" color="primary" onClick={handleStart}>
                {props.rest} <TimerOutlinedIcon fontSize='small' color='primary' />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Rest Timer</DialogTitle>
                <DialogContent style={{width: '250px'}}>
                    <Typography variant="h3" fontSize={'5rem'} color={time.minutes === 0 && time.seconds < 10 ? 'red' : '#333'} textAlign='center'>
                        {time.minutes > 9 ? time.minutes : `0${time.minutes}`}:{time.seconds > 9 ? time.seconds : `0${time.seconds}`}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}} >
                    <Button onClick={handleClose} color="info">
                        Close
                    </Button>
                    <Button onClick={handleRestart} color="primary">
                        Restart
                    </Button>
                </DialogActions>
            </Dialog>  
        </div>
    );

}