import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

function Account() {
    const account = useSelector((state: RootState) => state.account.accountDetails);
    const loading = useSelector((state: RootState) => state.account.loading);
    const error = useSelector((state: RootState) => state.account.error);
    useEffect(() => {
        console.log(account);
    }
    , [account]);
  return (
    <div>
        <h1>Account</h1>
        <div>
            {account && !loading ? 
            <div>
                <h2>{account.first_name}</h2>
            </div>
            : <div>
                <h2>Loading...</h2>
            </div>
                
                    }
        </div>
    </div>
  );
};

export default Account;
