import React, { useEffect, useState, useCallback } from 'react';
import CardInputs from './CardInputs';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../util/Confirmation';
import * as types from '../../../tsUtils';
import BaseDialog from '../dialogs/BaseDialog';
import {
  CardInputsClass,
  Definition,
  ExerciseBaseInterface,
  ExerciseCardInterface,
} from '../../../tsUtils';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, TextField } from '@mui/material';
import { RootState } from '../../../store/store';
import * as appActions from '../../../store/app/actions';
import TimeInput from '../../util/TimeInput';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { debounce } from 'lodash';
import '../../../style/Coach/Card.scss';
import { StyledInput } from '../../../common/StyledInput';
import ClientInputs from './ClientInputs';
import { ClientInputsInterface } from '../../../tsUtils/clientInputsTypes';
import ExerciseSearch from './ExerciseSearch';
import PopupMenu from '../../../common/PopupMenu';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface Props {
  card: ExerciseCardInterface;
  index: number;
  remove: (id: number) => void;
  inputOptions: Definition[];
  id: number;
}

function ExerciseCardForm(props: Props) {
  const client = useSelector((state: RootState) => state.exerciseCards.currentClient);
  const [clientInputs, setClientInputs] = useState<null | ClientInputsInterface>();
  const [useUserExercise, setUseUserExercise] = useState<boolean>(
    props.card.userexercise ? true : false
  );
  const [selectedExercise, setselectedExercise] = useState<types.ExerciseBaseInterface>(
    props.card.exercise ?? ({} as types.ExerciseBaseInterface),
  );
  const methods = useForm<ExerciseCardInterface>({
    defaultValues: {  ...props.card, comment: props.card.comment || "" },
  });
  const dispatch = useDispatch();
  const save = (formData: ExerciseCardInterface) => {
    console.log('save', formData)
    dispatch(
      exerciseCardActions.updateExerciseCard({
        ...formData,
        inputs: new CardInputsClass({ ...formData.inputs }),
      }),
    );
  };

  const intervalSave = useCallback(
    debounce(async () => {
      await methods.handleSubmit(save, onError)();
    }, 1000),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);
  useEffect(() => {
    let inputs = props.card.clientinputs?.find(inputs => inputs.client === client);
    if (inputs) {
      setClientInputs(inputs);
    } else {
      setClientInputs(null);
    }
  }, [client]);

  useEffect(() => {
    if ((Object.entries(methods.formState.dirtyFields).length > 0 || methods.formState.isDirty) && !methods.formState.isSubmitted) {
      intervalSave();
    }
  }, [methods.formState]);

  useEffect(() => {
    if (methods.formState.isSubmitted && methods.formState.isDirty) {
      methods.reset(props.card);
    }
    if (props.card.id !== methods.getValues('id')) {
      methods.reset(props.card);
    }
  }, [props.card]);

  return (
    <FormProvider {...methods}>
      <div className="exercise_card">
        <div className="cardHead">
          <div className="head_item">
            <input
              className="cardNo"
              type="text"
              {...methods.register(`card_no`)}
              defaultValue={props.card.card_no}
              name="card_no"
            />
          </div>
          <div className="head_item">
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Controller
                control={methods.control}
                name={`userexercise`}
                render={({ field }) => (
                  <StyledInput
                    {...field}
                    value={field.value || ''}
                    style={{ display: !useUserExercise ? 'none' : 'flex' }}
                    variant="outlined"
                    className="variable"
                    size="small"
                    title="Exercise"
                  />
                )}
              />
              {!useUserExercise && (
                <ExerciseSearch
                  selectedExercise={selectedExercise}
                  cardIndex={props.index}
                  onSelectExercise={exercise => {
                    methods.setValue('exercise', exercise, { shouldDirty: true, shouldTouch: true });
                    setselectedExercise(exercise);
                  }}
                />
              )
              }
              <PopupMenu
                popoverId={`popup_input_typ${props.index}`}
                icon={<SwapVertOutlinedIcon fontSize="small" />}
                menuItems={[
                  {
                    text: 'Custom',
                    icon: <EditOutlinedIcon fontSize="small" />,
                    action: () => {
                      methods.setValue('exercise', null, { shouldDirty: true, shouldTouch: true });
                      setselectedExercise({} as ExerciseBaseInterface)
                      setUseUserExercise(true);
                    },
                  },
                  {
                    text: 'Search',
                    icon: <SearchOutlinedIcon fontSize="small" />,
                    action: () => {
                      methods.setValue('userexercise', '', { shouldDirty: true, shouldTouch: true });
                      setUseUserExercise(false);
                    },
                  },
                ]}
              />
            </Box>
          </div>
          <div className="head_item">
            <BaseDialog
              title={'Confirm'}
              buttonSize="large"
              buttonText={<DeleteIcon fontSize="inherit" />}
              buttonColour="error"
              buttonWidth={30}
              fab
            >
              <Confirmation
                cancel={() => dispatch(appActions.closeDialogs())}
                confirm={() => props.remove(props.id)}
                confirmcolour="#d94136"
                confirmmessage="Delete"
                message="Are you sure you want to delete this exercise card?"
              />
            </BaseDialog>
          </div>
        </div>
        <Grid className="coach-variation-cont" container spacing={1}>
          <div className="coach-variation">
            <Controller
              control={methods.control}
              name={`var1`}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  variant="outlined"
                  className="variable"
                  size="small"
                  title="Variation 1"
                  label="Variation 1"
                />
              )}
            />
          </div>
          <div className="coach-variation">
            <Controller
              control={methods.control}
              name={`var2`}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  variant="outlined"
                  className="variable"
                  size="small"
                  title="Variation 2"
                  label="Variation 2"
                />
              )}
            />
          </div>
          <div className="coach-variation">
            <Controller
              control={methods.control}
              name={`eqi1`}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  variant="outlined"
                  className="variable"
                  size="small"
                  title="Equipment 1"
                  label="Equipment 1"
                />
              )}
            />
          </div>

          <div className="coach-variation">
            <Controller
              control={methods.control}
              name={`eqi2`}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  variant="outlined"
                  title="Equipment 2"
                  size="small"
                  className="variable"
                  label="Equipment 2"
                />
              )}
            />
          </div>

          <div className="coach-variation">
            <Controller
              control={methods.control}
              name={`tempo`}
              render={({ field }) => (
                <StyledInput
                  {...field}
                  variant="outlined"
                  className="variable"
                  title="Tempo"
                  size="small"
                  label="Tempo"
                  inputProps={{ pattern: '[0-9]/[0-9]/[0-9]/[0-9]' }}
                />
              )}
            />
          </div>

          <div className="coach-variation">
            <Controller
              name={`rest`}
              control={methods.control}
              render={({ field }) => (
                <TimeInput
                  onChange={field.onChange}
                  // onBlur: Noop;
                  value={field.value}
                  format="mm:ss"
                  size="medium"
                  className="variable"
                />
              )}
            />
          </div>
        </Grid>
        <Grid>
          {!client ? (
            <CardInputs
              inputOptions={props.inputOptions}
              cardIndex={props.index}
              inputs={props.card.inputs}
            />
          ) : (
            <div>
              {clientInputs ? (
                <ClientInputs
                  inputOptions={props.inputOptions}
                  cardIndex={props.index}
                  inputs={clientInputs.inputs}
                />
              ) : (
                <div>No client inputs</div>
              )}
            </div>
          )}
          {clientInputs ? (
            <TextField
              maxRows={3}
              aria-label="maximum height"
              className="cardComment"
              label="Comment"
              size="small"
              multiline
              disabled
              value={clientInputs.comment}
              inputProps={{ maxLength: 400 }}
            />
          ) : (
            <Controller
              control={methods.control}
              name={`comment`}
              render={({ field }) => (
                <TextField
                  {...field}
                  maxRows={3}
                  aria-label="maximum height"
                  className="cardComment"
                  label="Comment"
                  size="small"
                  multiline
                  inputProps={{ maxLength: 400 }}
                />
              )}
            />
          )}
        </Grid>
      </div>
    </FormProvider>
  );
}

export default ExerciseCardForm;
