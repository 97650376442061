import { CreateTemplateRequest } from '../../+store/programs/types';
import {
  ExerciseCardClass,
  PaginationResults,
  SessionInterface,
  WorkoutClass,
  WorkoutDetailInterface,
  WorkoutInterface,
} from '../../tsUtils';
import {
  ProgramDetailInterface,
  ProgramInterface,
  PostProgramInterface,
  ProgramConfigClass,
} from '../../tsUtils/programTypes';
import { TemplateInterface } from '../../tsUtils/templateTypes';
import { PromptQuestions } from '../programs/dialogs/forms/ProgramInfoForm';
import * as paths from './paths';
import { getAuthHeader } from './paths';

export async function getPrograms(query: string | null): Promise<PaginationResults<ProgramInterface[]>> {
  const endpoint = `${paths.COACHPROGRAM}${query}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function getProgram(id: number): Promise<ProgramDetailInterface> {
  const endpoint = `${paths.COACHPROGRAM}/${id}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function removeProgramWeek(
  id: number,
  week_no: number,
): Promise<ProgramInterface | string> {
  const endpoint = `${paths.COACHPROGRAM}/${id}/${week_no}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function createProgram(
  program: PostProgramInterface,
): Promise<ProgramDetailInterface> {
  const endpoint = `${paths.COACHPROGRAM}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(program),
  }).then(
    (response: Response) => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateProgram(program: PostProgramInterface): Promise<ProgramInterface> {
  const endpoint = `${paths.COACHPROGRAM}/${program.id}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(program),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function addProgramWeek(programId: number): Promise<string> {
  const endpoint = `${paths.COACHPROGRAM}/${programId}/addweek`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateProgramDetails(
  programId: number,
  details: {
    program_config: ProgramConfigClass;
    workouts: WorkoutInterface[];
    sessions: SessionInterface[];
    exerciseCards: ExerciseCardClass[];
  },
): Promise<{ program: ProgramDetailInterface }> {
  const endpoint = `${paths.COACHPROGRAM}/${programId}/workouts`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(details),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function deleteProgram(id: number): Promise<Response> {
  const endpoint = `${paths.COACHPROGRAM}/${id}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw new Error(error);
    });
}

export async function createTemplate(name: string, programId: number): Promise<TemplateInterface> {
  const endpoint = `${paths.COACHPROGRAM}/${programId}/template`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify({ name }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function generateProgram(
  programDetails: PostProgramInterface & { prompt: PromptQuestions },
): Promise<ProgramDetailInterface> {
  const endpoint = paths.GENERATEPROGRAM;
  const headers = getAuthHeader();

  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(programDetails),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return error;
    },
  );
}
