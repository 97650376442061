import React, { useState } from 'react';
interface Props {
  colour?: string;
  changeColour: (colour: string) => void;
}

export function ColourPicker(props: Props) {
  const [open, setopen] = useState(false);
  const [colour, setColour] = useState(props.colour);
  const colours = [
    '#ba68c8',
    '#64b5f6',
    '#81c784',
    '#fff176',
    '#ff8a65',
    '#f06292',
    '#e57373',
    '#55cbcd',
    '#222222',
  ];
  const handleSelectColour = (col: string) => {
    setColour(col);
    props.changeColour(col);
    setopen(!open);
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: colour,
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          cursor: 'pointer',
          border: '2px solid rgb(180, 180, 180)',
        }}
        onClick={() => {
          setopen(!open);
        }}
      ></div>
      <div
        style={{
          display: open ? 'flex' : 'none',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          gap: '5px',
          zIndex: 9999,
          width: '150px',
          height: '150px',
          backgroundColor: 'white',
          borderRadius: '4px',
          position: 'fixed',
          border: '1px solid #aaa',
          padding: '5px',
          boxShadow: '0px 8px 16px #888888bb',
        }}
      >
        {colours.map(col => (
          <span
            key={col}
            onClick={() => handleSelectColour(col)}
            style={{
              backgroundColor: col,
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              cursor: 'pointer',
              border: '2px solid rgb(180, 180, 180)',
            }}
          ></span>
        ))}
      </div>
    </div>
  );
}
