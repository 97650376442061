import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { WorkoutInterface } from '../../../tsUtils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { workoutSelectors } from '../../../+store/workouts/reducers';
import { sessionActions } from '../../../+store/sessions/actionTypes';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useConfigDateTime } from '../hooks/UseConfigDate';
interface Props {
  title: string;
  programid: number;
  startDate: Date;
  config: {
    day: Day;
    week: number;
  };
  open: boolean;
  onCancel: () => void;
}
interface RepeatForm {
  workout: WorkoutInterface;
  time: Date;
}

function RepeatWorkoutDialog(props: Props) {
  const [open, setOpen] = useState(false);

  const loading = useSelector((state: RootState) => state.workouts.loading);
  const loaded = useSelector((state: RootState) => state.workouts.loaded);
  const workouts = useSelector((state: RootState) => workoutSelectors.selectAll(state));
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RepeatForm>({
    defaultValues: {
      time: new Date(),
    },
  });
  const {dateTime, setDateTime, setInitialDateTime} = useConfigDateTime( new Date());
  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<RepeatForm> = data => {
    dispatch(
      sessionActions.repeatWorkout({
        ...props.config,
        program: props.programid,
        workout: data.workout.id,
        dateTime: dateTime.toISOString(),
      }),
    );
    if (loaded) {
      reset({
        time: new Date(),
      });
      props.onCancel();
    }
  };

  useEffect(() => {
    if (props.open) {
      setInitialDateTime(props.startDate, props.config.day, props.config.week, new Date());
    }
  }, [props.open]);

  function onCancel() {
    props.onCancel();
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent
        sx={{
          height: 270,
          width: 350,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <div style={{ width: 300 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <Controller
                  control={control}
                  name="workout"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(event: any, value: any) => field.onChange(value)}
                      options={workouts}
                      sx={{ width: '95%', marginLeft: '2.5%', marginRight: '2.5%' }}
                      size="small"
                      isOptionEqualToValue={(option: WorkoutInterface, value: WorkoutInterface) =>
                        option.id === value.id
                      }
                      title="Select Workout"
                      getOptionLabel={params => params.name}
                      renderInput={params => (
                        <TextField {...params} variant="standard" label="Select Workout" />
                      )}
                    />
                  )}
                />
                <TimePicker
                  value={dateTime}
                  onChange={(newValue) => {
                    setDateTime(newValue as Date);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="filled"
                      title="Workout Time"
                      label="Workout Time"
                    />
                  )}
                />
                  
              </div>

              <div className="form-buttons">
                <Button onClick={() => onCancel()} variant="outlined" color="info">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </div>
            </form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default RepeatWorkoutDialog;
