import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

interface Props {
  title: string;
  value: string;
  open: boolean;
  onConfirm: (value: string) => void;
  onCancel: () => void;
}

function RenameDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const [value, setvalue] = useState('');

  function handleConfirm() {
    props.onConfirm(value);
    setvalue('');
  }
  function handleCancel() {
    props.onCancel();
    setvalue('');
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent
        sx={{
          width: '300px',
          height: '150px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <TextField variant="filled" label="Name" value={value} onChange={event => setvalue(event.target.value)} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <Button variant="outlined" color="info" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default RenameDialog;
