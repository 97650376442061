import { AccountDetails } from "../../tsUtils/userTypes";
import * as paths from "./paths";
import { getAuthHeader } from "./paths";

export async function getAccountDetails(): Promise<AccountDetails> {
    const endpoint = `${paths.COACHACCOUNT}`;
    const headers = getAuthHeader();
    return await fetch(endpoint, {
      method: 'GET',
      headers,
    }).then(
      response => {
        if (response.ok) {
          return response.json();
        }
      },
      error => {
        return { error };
      },
    );
  }