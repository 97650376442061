// Form to create a new program template
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/Coach/ProgramForm.scss';
import * as appActions from '../../../store/app/actions';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../../../store/store';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TemplateInterface } from '../../../tsUtils/templateTypes';
import TemplateProgram from '../TemplateProgram';
interface Props {
  template: TemplateInterface;
  open: boolean;
  onClose: () => void;
}
const templateStyles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: '1200px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      maxWidth: '100%',
    },
  },
};

function ViewTemplate(props: Props) {
  const loading = useSelector((state: RootState) => state.programs.loading);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = React.useState<any>(props.template);
  const dispatch = useDispatch();

  // useeffect updates the program id and name when the dialog is opened
  useEffect(() => {
    setTemplate(props.template);
  }, [props.template]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  function onCancel() {
    props.onClose();
  }

  return (
    <Dialog fullScreen={isMobile} sx={templateStyles.dialogContainer} open={open}>
      <DialogTitle style={{ width: '100%', textAlign: 'right' }}>
        <IconButton onClick={onCancel} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent >
        <div style={templateStyles.container}>
          {loading ? <CircularProgress /> : <TemplateProgram template={template} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ViewTemplate;
