import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { programSelectors } from '../../../+store/programs/reducers';
import { RootState } from '../../../store/store';
import { ColourPicker } from '../../../common/ColourPicker';
import { Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import * as appActions from '../../../store/app/actions';
import { getAuthHeader } from '../../services/paths';

export interface IAppProps {
}

const style: any = {
    width: '100%',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
}

export default function App (props: IAppProps) {
  const programs = useSelector((state: RootState) => programSelectors.selectAll(state));
  const [colour, setColour] = React.useState('#ba68c8')
  const [selectedProgram, setSelectedProgram] = React.useState<number>(programs[0].id)
  const dispatch = useDispatch() 
  function onClose() {
    dispatch(appActions.closeDialogs());
  }
  async function onSubmit(e:React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const params = new URLSearchParams({
        program_id: selectedProgram.toString(),
        background_colour: colour
    })
    await fetch(`${form.action}?${params.toString()}`, {
        method: form.method,
        headers: getAuthHeader(),
    }).then((response) => dispatch(appActions.closeDialogs()))
    
    
  }
  return (
    <form method="POST" action="/coachapp/api/events/add_program_events" onSubmit={onSubmit} style={style}>
        <span><IconButton sx={{float: 'right', margin:"-15px"}} onClick={onClose} ><Close/></IconButton></span>
        <Typography variant="h5">Add Program Events</Typography>
        <Select value={selectedProgram} fullWidth variant="filled" label="Program" name="program_id">
            {programs.map(program => (
                <MenuItem key={program.id} value={program.id}>
                    {program.name}
                </MenuItem>
            ))}
        </Select>
        
        <ColourPicker colour={colour} changeColour={setColour} /> 
       <span><Button type='submit' sx={{float: 'right', width: '130px'}} color="primary" variant="contained" className='button'>Add Events</Button></span>
    </form>
  );
}
