import { EventBase, EventDetails } from '../../tsUtils/eventTypes';
import * as paths from './paths';
import { getAuthHeader } from './paths';

export async function getEvents(): Promise<EventBase[]> {
  let endpoint = `${paths.COACHEVENT}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateEvent(event: EventBase): Promise<EventBase> {
  let endpoint = `${paths.COACHEVENT}/${event.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(event),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      console.log(error);
      return { error };
    },
  );
}
export async function createEvent(event: EventDetails): Promise<EventBase> {
  let endpoint = `${paths.COACHEVENT}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(event),
  }).then(
    (response: Response) => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function deleteEvent(id: number): Promise<string> {
  let endpoint = `${paths.COACHEVENT}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
