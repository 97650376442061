import React from 'react';
import { CardInputsClass } from '../tsUtils';

import { Grid, Avatar, Typography, debounce } from '@mui/material';

import '../style/Preview/ClientSets.scss';

import store from '../store/store';

interface Props {
  inputs: CardInputsClass | null;
  cardId: string;
}

function PreviewCardInputs(props: Props) {
  const getDefinitionName = (type: number) => {
    const inputType = store.getState().definitions?.inputs?.entities[type];
    if (inputType) {
      return inputType?.name?.length > 11 ? inputType?.initials : inputType?.name;
    }
    return '-';
  };

  const rows = props.inputs?.sets.map((set, index) => (
    <Grid
      container
      key={props.cardId + index}
      sx={{ display: 'flex', width: '100%', border: 'solid 1px light-gray' }}
    >
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar sx={{ width: 20, height: 20, fontSize: 'small', bgcolor: '#15998E' }}>
          {index + 1}
        </Avatar>
      </Grid>
      <Grid item xs={2.75} fontSize="small" className="inputCell">
        <div className="input-cell-cell">
          <span style={{ textAlign: 'center', borderRight: '1px solid #ccc' }}>
            {set.values1 ?? '-'}
          </span>
          <span style={{ textAlign: 'center' }}></span>
        </div>
      </Grid>
      <Grid item xs={2.75} fontSize="small" className="inputCell">
        <div className="input-cell-cell">
          <span style={{ textAlign: 'center', borderRight: '1px solid #ccc' }}>
            {set.values2 ?? '-'}
          </span>
          <span style={{ textAlign: 'center' }}></span>
        </div>
      </Grid>
      <Grid item xs={2.75} fontSize="small" className="inputCell">
        <div className="input-cell-cell">
          <span style={{ textAlign: 'center', borderRight: '1px solid #ccc' }}>
            {set.values3 ?? '-'}
          </span>
          <span style={{ textAlign: 'center' }}></span>
        </div>
      </Grid>
      <Grid item xs={2.75} fontSize="small" className="inputCell">
        <div className="input-cell-cell">
          <span style={{ textAlign: 'center', borderRight: '1px solid #ccc' }}>
            {set.values4 ?? '-'}
          </span>
          <span style={{ textAlign: 'center' }}></span>
        </div>
      </Grid>
    </Grid>
  ));

  return (
    <div className="cardInputs">
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography fontSize="x-small" variant="button">
            No.
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="button" fontSize="x-small" component="span">
            {props.inputs?.input_types.input1
              ? getDefinitionName(props.inputs.input_types.input1)
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="button" fontSize="x-small" component="span">
            {props.inputs?.input_types.input2
              ? getDefinitionName(props.inputs.input_types.input2)
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="button" fontSize="x-small" component="span">
            {props.inputs?.input_types.input3
              ? getDefinitionName(props.inputs.input_types.input3)
              : '-'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="button" fontSize="x-small" component="span">
            {props.inputs?.input_types.input4
              ? getDefinitionName(props.inputs.input_types.input4)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ width: '100%' }}>
        {rows}
      </Grid>
    </div>
  );
}

export default PreviewCardInputs;
