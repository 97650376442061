import { put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as workoutAPI from '../../coachApp/services/workoutAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  workoutsLoading,
  workoutsFail,
  getWorkoutsSuccess,
  createWorkoutSuccess,
  updateWorkoutSuccess,
  deleteWorkoutSuccess,
  renameWorkoutSuccess,
  workoutsLoaded,
  selectWorkoutWithSessions,
} from './reducers';
import {
  addWorkoutSuccess,
  programSlice,
  removeProgramWorkout,
  updateProgramConfigSuccess,
} from '../programs/reducers';
import store from '../../store/store';
import * as types from './types';
import { reduceWorkouts } from '../../store/utility';
import { getSessionsSuccess, deleteWorkoutSessions } from '../sessions/reducers';
import { getExerciseCardsSuccess } from '../exerciseCards/reducers';

export function* getWorkoutsSaga({ query }: types.GetWorkouts) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.getWorkouts> = yield workoutAPI.getWorkouts(
      query,
    );
    yield put(getWorkoutsSuccess(response));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* createWorkoutSaga({ workout, config, questions }: types.CreateWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.createWorkout> =
      yield workoutAPI.createWorkout(workout, questions, config);
    const { workouts, sessions } = reduceWorkouts([response]);
    yield put(
      updateProgramConfigSuccess({
        day: config.day,
        week: config.week,
        session_config: {
          workoutId: workouts[0].id,
          sessionId: sessions[0].id,
          dateTime: config.dateTime,
        },
      }),
    );

    yield put(createWorkoutSuccess(workouts[0]));

    yield put(getSessionsSuccess(sessions));

    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* generateWorkoutSaga({ workout, config, questions, prompt }: types.GenerateWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.generateWorkout> =
      yield workoutAPI.generateWorkout(workout, questions, config, prompt);
    const { workouts, sessions, exerciseCards } = reduceWorkouts([response]);
    yield put(
      updateProgramConfigSuccess({
        day: config.day,
        week: config.week,
        session_config: {
          workoutId: workouts[0].id,
          sessionId: sessions[0].id,
          dateTime: config.dateTime,
        },
      }),
    );

    yield put(createWorkoutSuccess(workouts[0]));

    yield put(getSessionsSuccess(sessions));

    yield put(getExerciseCardsSuccess(exerciseCards));

    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* updateWorkoutSaga({ workout }: types.UpdateWorkoutRequest) {
  try {
    // const response: SagaReturnType<typeof workoutAPI.updateWorkout> =
    //   yield workoutAPI.updateWorkout(workout);
    yield put(
      updateWorkoutSuccess({
        id: workout.id!,
        changes: { ...workout },
      }),
    );
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* updateWorkoutDetailsSaga({
  workout,
  questions,
}: types.UpdateWorkoutDetailsRequest) {
  try {
    const response: SagaReturnType<typeof workoutAPI.updateWorkout> =
      yield workoutAPI.updateWorkout(workout, questions);
    const { workouts, sessions } = reduceWorkouts([response]);
    yield put(getSessionsSuccess(sessions));
    yield put(
      updateWorkoutSuccess({
        id: workout.id!,
        changes: { ...workouts[0] },
      }),
    );
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* renameWorkoutSaga({ workoutId, name }: types.RenameWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.renameWorkout> =
      yield workoutAPI.renameWorkout(workoutId, name);
    yield put(renameWorkoutSuccess(response));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* deleteWorkoutSaga({ workoutId, programId }: types.DeleteWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.deleteWorkout> =
      yield workoutAPI.deleteWorkout(workoutId);
    yield put(deleteWorkoutSuccess(workoutId));
    yield put(removeProgramWorkout({ workoutId, programId }));
    yield put(deleteWorkoutSessions(workoutId));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield put(workoutsFail(error));
  }
}
export function* copyWorkoutSaga({ workoutId }: types.CopyWorkoutRequest) {
  try {
    yield put(workoutsLoading());
    const response: SagaReturnType<typeof workoutAPI.copyWorkout> = yield workoutAPI.copyWorkout(
      workoutId,
    );
    const { workouts, sessions, exerciseCards } = reduceWorkouts([response]);
    yield put(
      addWorkoutSuccess({
        programId: workouts[0]?.program,
        workout: {
          id: workouts[0].id,
          order: workouts[0].order,
        },
      }),
    );
    yield put(createWorkoutSuccess(workouts[0]));
    yield put(getSessionsSuccess(sessions));
    yield put(getExerciseCardsSuccess(exerciseCards));
    yield put(workoutsLoaded());
  } catch (error: any) {
    yield console.log(error);
    yield put(workoutsFail(error));
  }
}
export function* workoutSaga() {
  yield takeLatest(actionTypes.GET_WORKOUTS, getWorkoutsSaga);
  yield takeLatest(actionTypes.CREATE_WORKOUT_REQUEST, createWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_WORKOUT_REQUEST, updateWorkoutSaga);
  yield takeLatest(actionTypes.UPDATE_WORKOUT_DETAILS_REQUEST, updateWorkoutDetailsSaga);
  yield takeLatest(actionTypes.DELETE_WORKOUT_REQUEST, deleteWorkoutSaga);
  yield takeLatest(actionTypes.COPY_WORKOUT_REQUEST, copyWorkoutSaga);
  yield takeLatest(actionTypes.RENAME_WORKOUT_REQUEST, renameWorkoutSaga);
  yield takeLatest(actionTypes.GENERATE_WORKOUT_REQUEST, generateWorkoutSaga);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}
