import React from "react";
import { Table } from "@mui/material";
import { Link } from "react-router-dom";
import * as types from "../../../tsUtils";

interface Props {
  programList: types.ProgramInterface[];
  sessionList: types.SessionInterface[];
  itemsType: string;
}

function DashTable(props: Props) {
  switch (props.itemsType) {
    case "programs":
      return (
        <Table>
          <thead>
            <tr>
              <th>Index</th>
              <th>Client</th>
              <th>Program Name</th>
              <th>Macro-Cycle</th>
              <th>Meso-Cycle</th>
              <th>Start Date</th>
            </tr>
          </thead>
          <tbody>
            {props.programList ? (
              props.programList.map((program, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td title={`clients`}>
                    {program.client.map(client =>
                      client.first_name && client.last_name
                        ? client.first_name + client.last_name
                        : client.username,
                    )}
                  </td>
                  <td title={program.name}>
                    <Link to={`/client/program/${program.id}/0/0`} className="link">
                      {program.name}
                    </Link>
                  </td>
                  <td title="Macro">{program.macro}</td>
                  <td title="Meso">{program.meso}</td>
                  <td title="Start">{program.start.toISOString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>no programs</td>
              </tr>
            )}
          </tbody>
        </Table>
      );
    case "sessions":
      return (
        <Table>
          <thead>
            <tr>
              <th>Index</th>
              <th>Client</th>
              <th>Session Name</th>
              <th>Start Date</th>
              <th>Completed</th>
              <th>Date Completed</th>
            </tr>
          </thead>
          <tbody>
            {props.sessionList ? (
              props.sessionList.map((session, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>no programs</td>
              </tr>
            )}
          </tbody>
        </Table>
      );
  }
}
export default DashTable;
