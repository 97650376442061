// Form to create a new program template
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/Coach/ProgramForm.scss';
import * as appActions from '../../../store/app/actions';
import { RootState } from '../../../store/store';
import { clientSelectors } from '../../../+store/clients/reducers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { programActions } from '../../../+store/programs/actionTypes';
interface Props {
  programName: string;
  programId: number;
  open: boolean;
  onClose: () => void;
}
type Option = {
  label: string;
  value: number;
};
interface TemplateForm {
  name: string;
}
const templateStyles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
};

function NewTemplateForm(props: Props) {
  const loading = useSelector((state: RootState) => state.programs.loading);
  const loaded = useSelector((state: RootState) => state.programs.loaded);
  const [open, setOpen] = React.useState(false);
  const [programId, setProgramId] = React.useState<number>(props.programId);
  const [programName, setProgramName] = React.useState<string>(props.programName);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TemplateForm>({
    defaultValues: {
      name: 'New Template',
    },
  });

  // useeffect updates the program id and name when the dialog is opened
  useEffect(() => {
    setProgramId(props.programId);
    setProgramName(props.programName);
  }, [props.programId, props.programName]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSubmit: SubmitHandler<TemplateForm> = data => {
    dispatch(programActions.createTemplate(data.name, programId));
    if (loaded) {
      props.onClose();
    }
  };

  function onCancel() {
    props.onClose();
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Template From - <Typography>{programName}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: 450,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <div style={templateStyles.container}>
          {loading ? (
            <CircularProgress />
          ) : (
            <form style={templateStyles.form} onSubmit={handleSubmit(onSubmit)}>
              <div className="program-form-controls">
                <div style={{ gridRow: '1/2', gridColumn: '1/3' }}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="filled"
                        fullWidth
                        title="Template Name"
                        label="Template Name"
                      />
                    )}
                  />
                </div>
              </div>
              <div style={templateStyles.formButtons}>
                <Button onClick={() => onCancel()} variant="outlined" color="info">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Create Template
                </Button>
              </div>
            </form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default NewTemplateForm;
