import { FormControlLabel } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { WorkoutInterface } from '../../../tsUtils';
import { debounce } from 'lodash';
import { workoutActions } from '../../../+store/workouts/actionTypes';

export type WorkoutForm = WorkoutInterface;

type Props = {
  workout: WorkoutInterface;
};

function WorkoutFormComponent(props: Props) {
  const { register, control, getValues, setValue, formState, handleSubmit, reset } =
    useForm<WorkoutForm>({
      defaultValues: { ...props.workout },
    });
  const dispatch = useDispatch();

  const save = (formData: WorkoutForm) => dispatch(workoutActions.updateWorkout(formData));

  const intervalSave = useCallback(
    debounce(async () => {
      await handleSubmit(save, onError)();
    }, 500),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);
  useEffect(() => {
    if (formState.isDirty && !formState.isSubmitted) {
      intervalSave();
    }
  }, [formState]);

  useEffect(() => {
    if (formState.isSubmitted) {
      reset(props.workout);
    }
    if (props.workout.id !== getValues('id')) {
      reset(props.workout);
    }
  }, [props.workout]);

  return (
    <form className="warmup-cooldown">
      <Controller
        name="warmup"
        control={control}
        render={({ field }) => (
          <div className="warmup-cooldown-item">
            <FormControlLabel
              label="Warm-up"
              className="text-edit"
              labelPlacement="top"
              control={
                <ReactQuill
                  style={{
                    width: '100%',
                    background: 'none',
                    border: 'none',
                  }}
                  defaultValue="Warm-up"
                  theme="snow"
                  {...field}
                ></ReactQuill>
              }
            />
          </div>
        )}
      ></Controller>

      <Controller
        name="cooldown"
        control={control}
        render={({ field }) => (
          <div className="warmup-cooldown-item">
            <FormControlLabel
              label="Cool-down"
              labelPlacement="top"
              className="text-edit"
              control={
                <ReactQuill
                  style={{
                    width: '100%',
                    background: 'none',
                    border: 'none',
                  }}
                  defaultValue="Cooldown"
                  theme="snow"
                  {...field}
                ></ReactQuill>
              }
            />
          </div>
        )}
      ></Controller>
    </form>
  );
}

export default WorkoutFormComponent;
