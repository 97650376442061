import React, { ReactElement } from 'react';
import { Definition, ExerciseCardInterface, InputType, CardInputsClass } from '../../../tsUtils';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select, Grid, Button, Avatar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../style/Coach/Sets.scss';
import * as defaults from '../../../tsUtils/defaults';
import store from '../../../store/store';
import { StyledInput } from '../../../common/StyledInput';
import { StyledSelect } from '../../../common/StyledSelect';

interface Props {
  cardIndex: number;
  inputOptions: Definition[];
  inputs: CardInputsClass;
}

function CardInputs(props: Props) {
  const { register, control, getValues, setValue, watch } = useFormContext<ExerciseCardInterface>();
  const repsDef = props.inputOptions.find(input => input.name === 'Reps');
  const weightDef = props.inputOptions.find(input => input.name === 'Weight');
  const { fields, append, remove } = useFieldArray<ExerciseCardInterface, `inputs.sets`>({
    control,
    name: `inputs.sets`,
  });

  function addSet() {
    if (fields.length > 0) {
      append(defaults.newSet(fields[fields.length - 1]));
    } else {
      append(defaults.newSet());
    }
  }

  const options = props.inputOptions.map(opt => (
    <MenuItem key={opt.id} sx={{ fontSize: 'small' }} title={opt.name} value={opt.id}>
      {opt.name}
    </MenuItem>
  ));

  function getInput(
    type: number | null,
    reg:
      | `inputs.sets.${number}.values1`
      | `inputs.sets.${number}.values2`
      | `inputs.sets.${number}.values3`
      | `inputs.sets.${number}.values4`,
    value?: any,
  ) {
    let ratingOpt: ReactElement[] = [];
    for (let i = 1; i <= 10; i++) {
      ratingOpt.push(
        <MenuItem className="setInput" key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }

    let def;
    if (type) {
      def = store.getState().definitions.inputs.entities[type];
    }
    switch (def?.inputtype) {
      case InputType.Time:
        return (
          <Controller
            control={control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                {...field}
                variant="outlined"
                fullWidth={true}
                className="setInput"
                size="small"
              />
            )}
          />
        );
      case InputType.Percent:
        return (
          <Controller
            control={control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                className="setInput"
                type="number"
                variant="outlined"
                inputProps={{
                  pattern: '[0-9.]*',
                }}
                size="small"
                fullWidth
                {...field}
              />
            )}
          />
        );
      case InputType.Select:
        return (
          <Controller
            control={control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledSelect
                {...field}
                size="small"
                variant="outlined"
                sx={{ minHeight: '0px' }}
                fullWidth
                className="setInput"
              >
                {ratingOpt}
              </StyledSelect>
            )}
          />
        );
      default:
        return (
          <Controller
            control={control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                className="setInput"
                variant="outlined"
                size="small"
                fullWidth={true}
                {...field}
              />
            )}
          />
        );
    }
  }

  function clearValues(valueKey: 'values1' | 'values2' | 'values3' | 'values4') {
    fields.forEach((field, index) => {
      setValue(`inputs.sets.${index}.${valueKey}`, '');
    });
  }

  const rows = fields.map((set, index) => (
    <Grid container key={index} sx={{ display: 'flex', width: '100%' }}>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar sx={{ width: 24, height: 24, fontSize: 'small', backgroundColor: '#15998E' }}>
          {index + 1}
        </Avatar>
      </Grid>
      <Grid item xs={2.5} className="inputCell">
        {getInput(
          getValues(`inputs.input_types.input1`),
          `inputs.sets.${index}.values1`,
          set.values1,
        )}
      </Grid>
      <Grid item xs={2.5} className="inputCell">
        {getInput(
          getValues(`inputs.input_types.input2`),
          `inputs.sets.${index}.values2`,
          set.values2,
        )}
      </Grid>
      <Grid item xs={2.5} className="inputCell">
        {getInput(
          getValues(`inputs.input_types.input3`),
          `inputs.sets.${index}.values3`,
          set.values3,
        )}
      </Grid>
      <Grid item xs={2.5} className="inputCell">
        {getInput(
          getValues(`inputs.input_types.input4`),
          `inputs.sets.${index}.values4`,
          set.values4,
        )}
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <DeleteIcon
          color="error"
          fontSize="small"
          onClick={() => remove(index)}
          sx={{ cursor: 'pointer' }}
        />
      </Grid>
    </Grid>
  ));

  return (
    <div className="cardInputs">
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          No.
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            control={control}
            name={`inputs.input_types.input1`}
            defaultValue={props.inputs.input_types?.input1}
            render={({ field }) => (
              <Select
                className="inputType"
                MenuProps={{
                  onClick: event => event.stopPropagation(),
                  sx: { maxHeight: 250 },
                }}
                size="small"
                sx={{ fontSize: 'small' }}
                variant="standard"
                defaultValue={repsDef?.id}
                {...field}
                value={field.value ?? ''}
                onChange={event => {
                  field.onChange(event);
                  clearValues('values1');
                }}
              >
                {options}
                <MenuItem key={-1} sx={{ fontSize: 'small' }} title="-" value={''}>
                  -
                </MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            control={control}
            name={`inputs.input_types.input2`}
            defaultValue={props.inputs.input_types?.input2}
            render={({ field }) => (
              <Select
                className="inputType"
                size="small"
                sx={{ fontSize: 'small' }}
                MenuProps={{
                  onClick: event => event.stopPropagation(),
                  sx: { maxHeight: 250 },
                }}
                variant="standard"
                defaultValue={weightDef?.id}
                {...field}
                value={field.value ?? ''}
                onChange={event => {
                  field.onChange(event);
                  clearValues('values2');
                }}
              >
                {options}
                <MenuItem key={-1} sx={{ fontSize: 'small' }} title="-" value={''}>
                  -
                </MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            control={control}
            name={`inputs.input_types.input3`}
            defaultValue={props.inputs.input_types?.input3}
            render={({ field }) => (
              <Select
                className="inputType"
                size="small"
                sx={{ fontSize: 'small' }}
                MenuProps={{
                  onClick: event => event.stopPropagation(),
                  sx: { maxHeight: 250 },
                }}
                variant="standard"
                {...field}
                value={field.value ?? ''}
                onChange={event => {
                  field.onChange(event);
                  clearValues('values3');
                }}
              >
                {options}
                <MenuItem key={-1} sx={{ fontSize: 'small' }} title="-" value={''}>
                  -
                </MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            control={control}
            name={`inputs.input_types.input4`}
            defaultValue={props.inputs.input_types?.input4}
            render={({ field }) => (
              <Select
                className="inputType"
                size="small"
                sx={{ fontSize: 'small' }}
                MenuProps={{
                  onClick: event => event.stopPropagation(),
                  sx: { maxHeight: 250 },
                }}
                variant="standard"
                {...field}
                value={field.value ?? ''}
                onChange={event => {
                  field.onChange(event);
                  clearValues('values4');
                }}
              >
                {options}
                <MenuItem key={-1} sx={{ fontSize: 'small' }} title="-" value={''}>
                  -
                </MenuItem>
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid container sx={{ width: '100%' }}>
        {rows}
      </Grid>
      <Button fullWidth sx={{ height: '30px' }} onClick={() => addSet()}>
        + Set
      </Button>
    </div>
  );
}

export default CardInputs;
