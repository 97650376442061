import {
  ExerciseCardInterface,
  ProgramDetailInterface,
  SessionDetailInterface,
  SessionInterface,
  WorkoutDetailInterface,
  WorkoutInterface,
  ProgramInterface,
} from '../tsUtils';
interface ReducedWorkouts {
  workouts: WorkoutInterface[];
  sessions: SessionInterface[];
  exerciseCards: ExerciseCardInterface[];
}
interface ReducedSession {
  updated: SessionInterface;
  exerciseCards: ExerciseCardInterface[];
}
export function reduceWorkouts(array: WorkoutDetailInterface[]): ReducedWorkouts {
  const arrays = array.reduce(
    (obj, workout) => {
      const sessions = workout.sessions
        ? workout.sessions.map(session => {
            return {
              ...session,
              exerciseCards: session.exerciseCards
                ? session.exerciseCards
                    .map(s => {
                      return { id: s.id!, order: s.order };
                    })
                    .sort((a, b) => a.order - b.order)
                : [],
            };
          })
        : [];
      const exerciseCards = workout.sessions.reduce((cards, session) => {
        const sessionCards = session.exerciseCards ? session.exerciseCards : [];
        return [...cards, ...sessionCards];
      }, [] as ExerciseCardInterface[]);
      const newWorkout: WorkoutInterface = {
        ...workout,
        sessions: workout.sessions
          .map(s => {
            return { id: s.id!, order: s.order };
          })
          .sort((a, b) => a.order - b.order),
      };
      obj.workouts.push(newWorkout);
      obj.sessions = [...obj.sessions, ...sessions];
      obj.exerciseCards = [...obj.exerciseCards, ...exerciseCards];
      return obj;
    },
    { workouts: [], sessions: [], exerciseCards: [] } as ReducedWorkouts,
  );
  return arrays;
}
export function reduceProgram(
  program: ProgramDetailInterface,
): ReducedWorkouts & { selectedProgram: ProgramInterface } {
  const selectedProgram = {
    ...program,
    workouts: program.workouts.map(wo => {
      return { id: wo.id, order: wo.order };
    }),
  };
  const arrays = program.workouts.reduce(
    (obj, workout) => {
      const sessions = workout.sessions
        ? workout.sessions.map(session => {
            return {
              ...session,
              exerciseCards: session.exerciseCards
                ? session.exerciseCards
                    .map(s => {
                      return { id: s.id!, order: s.order };
                    })
                    .sort((a, b) => a.order - b.order)
                : [],
            };
          })
        : [];
      const exerciseCards = workout.sessions.reduce((cards, session) => {
        const sessionCards = session.exerciseCards ? session.exerciseCards : [];
        return [...cards, ...sessionCards];
      }, [] as ExerciseCardInterface[]);
      const newWorkout: WorkoutInterface = {
        ...workout,
        sessions: workout.sessions
          .map(s => {
            return { id: s.id!, order: s.order };
          })
          .sort((a, b) => a.order - b.order),
      };
      obj.workouts.push(newWorkout);
      obj.sessions = [...obj.sessions, ...sessions];
      obj.exerciseCards = [...obj.exerciseCards, ...exerciseCards];
      return obj;
    },
    { workouts: [], sessions: [], exerciseCards: [] } as ReducedWorkouts,
  );
  return { ...arrays, selectedProgram };
}

export function reduceSession(sess: SessionDetailInterface): ReducedSession {
  const exerciseCards = sess.exerciseCards ? [...sess.exerciseCards] : [];
  const updated = {
    ...sess,
    exerciseCards: sess.exerciseCards
      ? sess.exerciseCards
          .map((s) => {
            return { id: s.id!, order: s.order };
          })
          .sort((a, b) => a.order - b.order)
      : [],
  };
  return { updated, exerciseCards };
}
