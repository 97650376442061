import { ExerciseCardInterface, SessionInterface } from '../../tsUtils';
import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import store, { RootState } from '../../store/store';

export interface SessionState extends EntityState<SessionInterface> {
  selectedId?: number;
  loading: boolean;
  loaded: Boolean;
  message?: string;
  error: any | null;
}

const sessionAdapter = createEntityAdapter<SessionInterface>({});

export const sessionInitialState: SessionState = sessionAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState: sessionInitialState,
  reducers: {
    sessionsLoading(state: SessionState) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    sessionsLoaded(state: SessionState) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    sessionsFail(state: SessionState, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    setSelectedSessionId(state: SessionState, action) {
      state.selectedId = action.payload;
    },
    getSessionsSuccess(state: SessionState, action) {
      sessionAdapter.upsertMany(state, action.payload);
    },
    createSessionSuccess(state: SessionState, action) {
      sessionAdapter.upsertOne(state, action.payload);
    },
    renameSessionSuccess(state: SessionState, action) {
      sessionAdapter.upsertOne(state, action.payload);
    },
    deleteSessionSuccess(state: SessionState, action) {
      sessionAdapter.removeOne(state, action.payload);
    },
    updateSessionSuccess(state: SessionState, action) {
      sessionAdapter.updateOne(state, action.payload);
    },
    updateSessionFeedbackSuccess(state: SessionState, action) {
      state.message = action.payload;
    },
    clearSelectedSession(state: SessionState) {
      let newState = state;
      delete newState.selectedId;
      state = newState;
    },
    deleteWorkoutSessions(state: SessionState, action: { payload: number }) {
      const toDeleteIds = sessionAdapter
        .getSelectors()
        .selectAll(state)
        .filter(session => session.workout === action.payload)
        .map(session => session.id!);

      sessionAdapter.removeMany(state, toDeleteIds);
    },
  },
});
export const selectDetailedSession = createSelector(
  [
    (state: RootState) => state.sessions.entities,
    (state: RootState) => state.exerciseCards.entities,
    (state: RootState, id: number) => id,
  ],
  (sessionEntities, cardEntities, id) => {
    let session = sessionEntities[id];
    let exerciseCards: ExerciseCardInterface[] = [];
    if (session) {
      exerciseCards = session.exerciseCards.reduce((a, c) => {
        let card = cardEntities[c.id];
        if (card) {
          card = {
            ...card,
            inputs: {
              ...card.inputs,
              sets: card.inputs.sets ?? [{ values1: '', values2: '', values3: '', values4: '' }],
            },
          };
          return [...a, card];
        }
        return a;
      }, [] as ExerciseCardInterface[]);
      return {
        ...session,
        exerciseCards,
      };
    }
  },
);
export const selectCurrentWorkoutSessionNameId = createSelector(
  [
    (state: RootState) => sessionSelectors.selectAll(state),
    (state: RootState) => state.workouts.selectedId,
  ],
  (sessions, workoutId) => {
    return sessions
      .filter(session => session.workout === workoutId)
      .sort((a, b) => a.order - b.order)
      .map(session => {
        return { id: session.id, name: session.name, order: session.order };
      });
  },
);
export const selectCurrentSession = createSelector(
  [(state: RootState) => state.sessions.entities, (state: RootState) => state.sessions.selectedId],
  (sessions, sessionId) => {
    return sessionId ? sessions[sessionId] : null;
  },
);

// select previous session in the same workout
export const selectPreviousSession = createSelector(
  [
    (state: RootState) => state.sessions.entities,
    (state: RootState) => state.sessions.selectedId,
    // select current workout entity
    (state: RootState) => state.workouts.entities[state.workouts.selectedId!],
  ],
  (sessions, sessionId, workout) => {
    if (!sessionId) return null;
    if (!workout) return null;
    const sessionIndex = workout.sessions.findIndex(s => s.id === sessionId);
    if (sessionIndex === 0) return null;
    const previousSession = workout.sessions[sessionIndex - 1];
    return sessions[previousSession.id];
  },
);

export const {
  sessionsLoading,
  sessionsLoaded,
  sessionsFail,
  setSelectedSessionId,
  getSessionsSuccess,
  createSessionSuccess,
  deleteSessionSuccess,
  updateSessionSuccess,
  renameSessionSuccess,
  deleteWorkoutSessions,
  updateSessionFeedbackSuccess,
} = sessionSlice.actions;

export const sessionSelectors = sessionAdapter.getSelectors<RootState>(state => state.sessions);
