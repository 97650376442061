import React, { useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import Save from '@mui/icons-material/Save';
import { savedSuccess } from '../store/app/actions';

// animation to slide saved icon in from bottom then fade out
const savedIcon: any = {
    position: 'fixed',
    bottom: '-75px',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    left: '50px',
    zIndex: 1000,
    animation: 'slidein 4s ease-in-out',
    backgroundColor: 'white',
    border: '.5px solid #15998E',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#15998E',
    borderRadius: '5px',
    padding: '5px 10px',
    opacity: 0,
};

const Saved: React.FC = () => {
    const dispatch = useDispatch();
    const saved = useSelector((state: RootState) => state.app.saved);
    
    useEffect(() => {
        if (saved) {
            setTimeout(() => {
                dispatch(savedSuccess());
            }, 4000);
        }
    }, [saved]);

    return (
        <>
            {saved && <div style={savedIcon} ><Save /> { saved }</div>}
        </>
        
    );
};

export default Saved;
