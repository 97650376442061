export interface Definition {
  id?: number;
  name: string;
  classification: DefinitionClassification;
  inputtype: InputType;
  initials?: string;
  units?: string;
  description: string;
}

export enum DefinitionClassification {
  ExerciseCategory = "EXERCISE_CATEGORY",
  ExerciseClass = "EEXERCISE_CLASS",
  JointAction = "JOINT_ACTION",
  MuscleGroup = "MUSCLE_GROUP",
  ExerciseVariation = "EXERCISE_VARIATION",
  InputVariable = "INPUT_VARIABLE",
  TrackingVariable = "TRACKING_VARIABLE",
}
export enum InputType {
  Text = 0,
  Number = 1,
  Date = 2,
  Time = 3,
  Select = 4,
  Boolean = 5,
  Percent = 6,
}
