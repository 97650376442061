import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from '../../../coachApp/services/definitionsAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import * as actionTypes from './actionTypes';
import {
  definitionsFail,
  definitionsLoaded,
  definitionsLoading,
  getDefinitionsSuccess,
} from './reducers';

export function* getDefinitions(): any {
  try {
    yield put(definitionsLoading());
    const response = yield call(API.getDefinitions);
    yield put(getDefinitionsSuccess(response));
    yield put(definitionsLoaded());
  } catch (error) {
    yield put(definitionsFail(error));
  }
}
export function* definitionSaga() {
  yield takeLatest(actionTypes.GET_DEFINITIONS, getDefinitions);
}
