import { Button, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkoutInterface } from '../../../tsUtils';
import SessionForm from '../../../coachApp/programs/components/SessionForm';
import ReactQuill from 'react-quill';
import { getSelectedWorkout } from '../../../+store/workouts/reducers';
import { selectCurrentSession } from '../../../+store/sessions/reducers';
import { RootState } from '../../../store/store';
import '../../ClientStyle/ClientWorkout.scss';
export type WorkoutForm = WorkoutInterface;

interface Props {}
const wrap: 'wrap' | 'nowrap' = 'wrap';

const ContainerStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '0px 0px 15px 0px',
  alignItems: 'center',

  flexWrap: wrap,
};
const ItemStyle = {
  minHeight: 100,
  maxWidth: '900px',
  width: 'calc(100% - 20px)',
  minWidth: 350,
  backgroundColor: 'white',
  borderRadius: '6px',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.3)',
};

function ClientWorkout(props: Props) {
  const workout = useSelector((state: RootState) => getSelectedWorkout(state));
  const session = useSelector((state: RootState) => selectCurrentSession(state));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (workout && session) {
      setLoading(false);
    }
  }, [workout, session]);

  return (
    <>
      {workout && session ? (
        <div style={ContainerStyle}>
          <div style={ItemStyle}>
            <Typography
              variant="button"
              sx={{ textAlign: 'center', width: '100%', fontSize: '.9em' }}
              component="div"
            >
              Warm-up
            </Typography>
            <div className="text-edit-client">
              {workout.warmup ? (
                <ReactQuill readOnly theme="snow" value={workout.warmup}></ReactQuill>
              ) : (
                <Typography sx={{ textAlign: 'center' }} variant="h6">
                  -
                </Typography>
              )}
            </div>
          </div>
          <SessionForm workoutId={workout.id} session={session} />
          <div style={ItemStyle}>
            <Typography
              variant="button"
              sx={{ textAlign: 'center', width: '100%', fontSize: '.9em' }}
              component="div"
            >
              Cool-down
            </Typography>
            <div className="text-edit-client">
              {workout.cooldown ? (
                <ReactQuill readOnly theme="snow" value={workout.cooldown}></ReactQuill>
              ) : (
                <Typography sx={{ textAlign: 'center' }} variant="h6">
                  -
                </Typography>
              )}
            </div>
          </div>
          <div className="program-footer">
            <div style={{ color: 'green' }}></div>
          </div>
        </div>
      ) : (
        <LinearProgress sx={{ width: '100%' }} />
      )}
    </>
  );
}

export default ClientWorkout;
