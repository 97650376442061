import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as authAPI from "./authAPI";
import * as authActions from "./actions";
import * as types from "./types";
import * as actionTypes from "../actionTypes";
import { AccountType } from '../../tsUtils';

export function* login({ creds }: types.LoginAction): any {
  try {
    const response: SagaReturnType<typeof authAPI.login> = yield call(authAPI.login, creds);
    if (response.user) {
      yield localStorage.setItem('login_page', AccountType.Coach);
      yield put(authActions.authSuccess(response));
    } else {
      yield put(authActions.loginFail(response.message));
    }
  } catch (error) {
    yield console.log(error);
    yield put(authActions.loginFail(error));
  }
}
export function* clientLogin({ creds }: types.LoginAction): any {
  try {
    const response: SagaReturnType<typeof authAPI.login> = yield call(authAPI.clientLogin, creds);

    if (response.user) {
      yield localStorage.setItem('login_page', AccountType.Client);
      yield put(authActions.authSuccess(response));
    } else {
      yield put(authActions.loginFail(response.message));
    }
  } catch (error) {
    yield put(authActions.loginFail(error));
  }
}
export function* logout(): any {
  try {
    const response: SagaReturnType<typeof authAPI.logout> = yield call(authAPI.logout);
    yield put(authActions.logoutSuccess(response.message));
  } catch (error) {
    yield console.log(error);
  }
}
export function* checkAuthState() {
  try {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const expirationDate = localStorage.getItem('expiration');
    if (user && token && expirationDate && new Date(expirationDate) > new Date()) {
      yield put(authActions.authSuccess({ token, user: JSON.parse(user), message: '' }));
    } else {
      yield put(authActions.logout());
    }
  } catch (error) {
    yield put(authActions.logout());
  }
}
export function* authSaga() {
  yield takeLatest(actionTypes.LOGIN, login);
  yield takeLatest(actionTypes.CLIENT_LOGIN, clientLogin);
  yield takeLatest(actionTypes.LOGOUT, logout);
  yield takeLatest(actionTypes.CHECK_AUTH_STATE, checkAuthState);
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}
