import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateClientForm from './components/ClientForm';
import '../../style/Coach/Clients.scss';
import '../../style/Coach/ListPage.scss';
import { clientSelectors } from '../../+store/clients/reducers';
import { RootState } from '../../store/store';
import BaseDialog from '../programs/dialogs/BaseDialog';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import UserAvatar from '../../common/UserAvatar';
import ActionButtons from '../renderers/TableActions';
import { User } from '../../tsUtils';
import { deleteClient } from '../../+store/clients/actionTypes';
import ConfirmDeleteClient from './components/ConfirmDeleteClient';
import PopupMenu from '../../common/PopupMenu';
import { DeleteOutlined, EditOutlined, MoreVert } from '@mui/icons-material';

function Clients() {
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const loading = useSelector((state: RootState) => state.clients.loading);
  const [deletingClient, setDeletingClient] = useState<User | null>(null);
  const [editingClient, setEditingClient] = useState<User | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {}, [clients]);

  const deleteItem = (data: User) => setDeletingClient(data);

  const copyItem = () => console.log('copy');

  const editItem = (data: any) => setEditingClient(data);

  const confirmDelete = (id: number) => {
    dispatch(deleteClient(id));
    setDeletingClient(null);
  };

  const options: ('edit' | 'copy' | 'delete')[] = ['edit', 'delete'];

  return (
    <div className="listPage">
      <div className="listgroup">
        <div className="listActions">
          <BaseDialog buttonWidth={160} title="Add New Client" buttonText="Add Client">
            <CreateClientForm  />
          </BaseDialog>
        </div>

        <div className="listDiv">
          <Box color="primary" className="list-header-row-clients">
            <Typography
              component="div"
              className="list-row-item item1"
              style={{ lineHeight: 1.1 }}
              variant="button"
            ></Typography>

            <Typography
              component="div"
              className="list-row-item item2"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Name
            </Typography>

            <Typography
              component="div"
              className="list-row-item item3"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Email
            </Typography>

            <Typography
              component="div"
              className="list-row-item item5"
              style={{ lineHeight: 1.1 }}
              variant="button"
            ></Typography>
          </Box>
          {loading ? (
            <div className="loading-cont">
              <CircularProgress />
            </div>
          ) : (
            <div className="list-body">
              {clients.length > 0 ? (
                clients.map(client => (
                  <div key={client.id} className="list-row-clients" onClick={() => editItem(client)}>
                    <div className="user-pill-cont item1">
                      <UserAvatar first={client.first_name} last={client.last_name} size={36} />
                    </div>
                    <Typography variant="body2" className="list-row-item item2" component="div">
                      {client.first_name
                        ? `${client.first_name} ${client.last_name}`
                        : `${client.username}`}
                    </Typography>

                    <Typography
                      variant="body2"
                      title={client.email}
                      className="list-row-item item3"
                      style={{ overflow: 'hidden', wordBreak: 'break-word' }}
                      component="div"
                    >
                      {client.email}
                    </Typography>
                    <div className="list-row-item item4"></div>

                    <div className="list-row-item item5">
                        <PopupMenu 
                          menuItems={[
                            {text: 'Edit', icon: <EditOutlined />, action: () => editItem(client)},
                            {text: 'Delete', icon: <DeleteOutlined />, action: () => deleteItem(client)}
                          ]} 
                          icon={<MoreVert />} 
                          popoverId={`client-list-${client.id}`} 
                        
                        />
                      </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    gridRow: '1/-1',
                    gridColumn: '1/-1',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color="InactiveCaptionText"
                    sx={{ width: '100%' }}
                    component="span"
                    variant="h4"
                  >
                    No Clients Found
                  </Typography>
                </div>
              )}
            </div>
          )}
          {deletingClient != null && (
            <ConfirmDeleteClient
              client={deletingClient}
              onConfirm={confirmDelete}
              onCancel={() => setDeletingClient(null)}
              open={deletingClient !== null}
            />
          )}
          {editingClient != null && (
            <Dialog open={editingClient != null}>
              <DialogTitle>Edit Client</DialogTitle>
              <DialogContent>
              <CreateClientForm onClose={() => setEditingClient(null)} client={editingClient} />
              </DialogContent>
            </Dialog>
            
          )}
        </div>
      </div>
    </div>
  );
}

export default Clients;
