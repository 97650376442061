export const COACH = "/coachapp/";
export const CLIENT = "/clientapp/";
export const COACHLOGIN = `${COACH}login/`;
export const COACHLOGOUT = `${COACH}logout/`;
export const COACHPROGRAM = `${COACH}api/program/`;
export const COACHWORKOUT = `${COACH}api/workouts/`;
export const COACHSESSION = `${COACH}api/sessions/`;
export const COACHCLIENT = `${COACH}api/clients/`;
export const COACHEXERCISE = `${COACH}api/exercise/`;
export const COACHDEFINITIONS = `${COACH}api/definitions/`;
export const COACHCARD = `${COACH}api/updatecard/`;
export const COACHSESSIONLIST = `${COACH}api/sessionlists/`;
export const CLIENTLOGIN = `${CLIENT}login/`;
export const CLIENTLOGOUT = `${CLIENT}logout/`;
export const CLIENTPROGRAM = `${CLIENT}api/program/`;
export const CLIENTEXERCISE = `${CLIENT}api/exercise/`;
export const CLIENTINPUTS = `${CLIENT}api/updateinputs/`;