import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  InputType,
  CardInputsClass,
} from '../../../tsUtils';

import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  MenuItem,
  Grid,
  Button,
  TextField,
  Avatar,
  Typography,
  debounce,
  Popover,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import '../../ClientStyle/ClientSets.scss';
import store from '../../../store/store';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { ClientInputsInterface } from '../../../tsUtils/clientInputsTypes';
import { useDispatch } from 'react-redux';
import { StyledInput } from '../../../common/StyledInput';
import { StyledSelect } from '../../../common/StyledSelect';

interface Props {
  cardIndex: number;
  inputs: ClientInputsInterface;
  prescribed: CardInputsClass;
}

function ClientCardInputs(props: Props) {
  const dispatch = useDispatch();
  const methods = useForm<ClientInputsInterface>({
    defaultValues: { ...props.inputs },
  });
  const { fields, append, remove } = useFieldArray<ClientInputsInterface, `inputs.sets`>({
    control: methods.control,
    name: `inputs.sets`,
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedInput, setSelectedInput] = React.useState<any>();
  const handleShowInfo = (
    event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedInput(null);
  };

  const open = Boolean(anchorEl);

  const save = (formData: ClientInputsInterface) => {
    dispatch(exerciseCardActions.updateClientInputsCard(props.inputs.card, formData));
  };

  const intervalSave = useCallback(
    debounce(async () => {
      await methods.handleSubmit(save, onError)();
    }, 2000),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);

  useEffect(() => {
    if (methods.formState.isDirty && !methods.formState.isSubmitted) {
      intervalSave();
    }
  }, [methods.formState]);

  useEffect(() => {
    if (methods.formState.isSubmitted && methods.formState.isDirty) {
      methods.reset(props.inputs);
    }
    if (props.inputs.id !== methods.getValues('id')) {
      methods.reset(props.inputs);
    }
  }, [props.inputs]);

  const getDefinitionName = (type?: number | null) => {
    let input = type && store.getState().definitions.inputs.entities[type];
    let id = input && open ? input.name : undefined;
    return !input ? (
      <Typography variant="body1" component="span">
        -
      </Typography>
    ) : (
      <>
        <Button
          aria-describedby={id}
          size="small"
          fullWidth
          sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px' }}
          onClick={event => {
            handleShowInfo(event);
            setSelectedInput(input);
          }}
          color="info"
        >
          {input.name.length < 8 ? input.name : input.initials} <InfoIcon fontSize="small" />
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography variant="button" sx={{ p: 2 }}>
            {selectedInput?.name}
          </Typography>
          <Typography variant="body1" color="GrayText" sx={{ p: 2 }}>
            {selectedInput?.description}
          </Typography>
        </Popover>
      </>
    );
  };

  function getInput(
    type: number | null,
    reg:
      | `inputs.sets.${number}.values1`
      | `inputs.sets.${number}.values2`
      | `inputs.sets.${number}.values3`
      | `inputs.sets.${number}.values4`,
    value?: any,
  ) {
    let ratingOpt: ReactElement[] = [];
    for (let i = 1; i <= 10; i++) {
      ratingOpt.push(
        <MenuItem className="setInput" key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }

    let def;
    if (type) {
      def = store.getState().definitions.inputs.entities[type];
    }
    switch (def?.inputtype) {
      case InputType.Time:
        return (
          <Controller
            control={methods.control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                {...field}
                variant="outlined"
                fullWidth={true}
                className="setInput"
                size="small"
              />
            )}
          />
        );
      case InputType.Percent:
        return (
          <Controller
            control={methods.control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                className="setInput"
                variant="outlined"
                type="number"
                inputProps={{
                  pattern: '[0-9.]*',
                }}
                size="small"
                fullWidth
                {...field}
              />
            )}
          />
        );
      case InputType.Select:
        return (
          <Controller
            control={methods.control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledSelect
                {...field}
                size="small"
                variant="outlined"
                sx={{ minHeight: '0px' }}
                fullWidth
                className="setInput"
              >
                {ratingOpt}
              </StyledSelect>
            )}
          />
        );
      default:
        return (
          <Controller
            control={methods.control}
            name={reg}
            defaultValue={value}
            render={({ field }) => (
              <StyledInput
                className="setInput"
                variant="outlined"
                size="small"
                fullWidth={true}
                {...field}
              />
            )}
          />
        );
    }
  }

  const rows = fields.map((set, index) => (
    <Grid container key={index} sx={{ display: 'flex', width: '100%' }}>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar sx={{ width: 24, height: 24, fontSize: 'small', backgroundColor: '#15998E' }}>
          {index + 1}
        </Avatar>
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        {getInput(
          methods.getValues(`inputs.input_types.input1`),
          `inputs.sets.${index}.values1`,
          set.values1,
        )}
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        {getInput(
          methods.getValues(`inputs.input_types.input2`),
          `inputs.sets.${index}.values2`,
          set.values2,
        )}
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        {getInput(
          methods.getValues(`inputs.input_types.input3`),
          `inputs.sets.${index}.values3`,
          set.values3,
        )}
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        {getInput(
          methods.getValues(`inputs.input_types.input4`),
          `inputs.sets.${index}.values4`,
          set.values4,
        )}
      </Grid>
    </Grid>
  ));

  return (
    <div className="cardInputs">
      <Grid container sx={{ width: 'calc(100% - 5px)' }}>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,
            padding: 0.25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.inputs.input_types.input1)}
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,
            padding: 0.25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.inputs.input_types.input2)}
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,
            display: 'flex',
            padding: 0.25,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.inputs.input_types.input3)}
        </Grid>
        <Grid
          item
          xs={2.75}
          sx={{
            flex: 1,
            display: 'flex',
            padding: 0.25,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.inputs.input_types.input4)}
        </Grid>
      </Grid>
      <Grid container sx={{ width: 'calc(100% - 5px)' }}>
        {rows}
      </Grid>
      <Grid container sx={{ width: 'calc(100% - 5px)' }}>
        <Controller
          control={methods.control}
          name={`comment`}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ''}
              maxRows={3}
              aria-label="maximum height"
              className="cardComment"
              label="Comment"
              size="small"
              multiline
            />
          )}
        />
      </Grid>
    </div>
  );
}

export default ClientCardInputs;
