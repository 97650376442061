import { User } from '../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

export interface ClientState extends EntityState<User> {
  loading: boolean;
  loaded: Boolean;
  error: any;
}

const clientAdapter = createEntityAdapter<User>({});

export const clientInitialState: ClientState = clientAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const clientSlice = createSlice({
  name: 'clients',
  initialState: clientInitialState,
  reducers: {
    clientsLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    clientsLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    clientsFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getClientsSuccess(state, action) {
      clientAdapter.upsertMany(state, action.payload);
    },
    createClientSuccess(state, action) {
      clientAdapter.upsertOne(state, action.payload);
    },
    deleteClientSuccess(state, action) {
      clientAdapter.removeOne(state, action.payload);
    },
    updateClientSuccess(state, action) {
      clientAdapter.upsertOne(state, action.payload);
    }
  },
});

export const {
  clientsLoading,
  clientsLoaded,
  clientsFail,
  getClientsSuccess,
  createClientSuccess,
  deleteClientSuccess,
} = clientSlice.actions;

export const clientSelectors = clientAdapter.getSelectors((state: RootState) => state.clients);
