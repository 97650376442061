import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useNavigate, useParams, Routes, Outlet } from 'react-router-dom';
// import {getProgram, clearProgram} from '../../../store/programs/actions';
import '../../style/Coach/Program.scss';
import '../../style/Coach/Workout.scss';
import { programActions } from '../../+store/programs/actionTypes';
import { RootState } from '../../store/store';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { ProgramConfigClass, ProgramConfigSession, SessionFeedbackRequest } from '../../tsUtils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { workoutSlice, getSelectedWorkout } from '../../+store/workouts/reducers';
import { sessionSlice, selectCurrentSession } from '../../+store/sessions/reducers';
import ClientProgramHead from './components/ClientProgramHead';
import { clearSelectedProgram, programSlice } from '../../+store/programs/reducers';
import ClientWorkout from './components/ClientWorkout';
import Programlist from '../../coachApp/programs/containers/Programlist';
import SessionFeedbackForm from './dialogs/SessionQuestionsForm';
import BaseDialog from '../../coachApp/programs/dialogs/BaseDialog';
import { PageActions } from '../../common/PageActions';
import { LocalPrintshopOutlined, SaveOutlined } from '@mui/icons-material';
import { set } from 'lodash';

interface Props {}

function ClientProgram(props: Props) {
  const { programId, workoutId, sessionId } = useParams();
  const program = useSelector((state: RootState) => state.programs.selectedProgram);
  const message = useSelector((state: RootState) => state.programs.message);
  const session = useSelector((state: RootState) => selectCurrentSession(state));
  const [openFeedback, setOpenFeedback] = useState(false);
  const [config, setConfig] = useState<ProgramConfigClass | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  useEffect(() => {
    if (programId && !program) {
      dispatch(programActions.getClientProgramWorkouts(Number(programId)));
    }
    if (workoutId && sessionId) {
      dispatch(sessionSlice.actions.setSelectedSessionId(Number(sessionId)));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(Number(workoutId)));
    }

  }, [programId, workoutId, sessionId]);
  useEffect(() => {
    if (program && program.program_config) {
      setConfig(program.program_config);
    }
  }, [program]);

  function saveProgram() {
    if (programId) {
      dispatch(programActions.updateClientInputs(Number(programId)));
    }
  }

  function sendFeedback(data: SessionFeedbackRequest) {
    if (programId) {
      dispatch(programActions.updateClientInputs(Number(programId), data));
      setOpenFeedback(false);
    }
  }

  const printProgram = () => {
    saveProgram();
    navigate(`/client/program/${programId}/print`);
  };

  function goBack() {
    if (workoutId) {
      dispatch(sessionSlice.actions.clearSelectedSession());
      dispatch(workoutSlice.actions.clearSelectedWorkout());
      dispatch(programSlice.actions.removeProgramMessage());
    }

    navigate(-1);
  }

  return programId ? (
    <div className="program-container">
      <div className="programBody">
        <div className="navStuff" style={{ marginBottom: '5px', marginTop: '5px' }}>
        <PageActions
          backButton={{ label: 'Back', link: workoutId ? `/client/programs/${programId}/` : '/client/programs/', }}
          backAction={workoutId ? undefined : clearSelectedProgram}
          buttonSize='medium'
          actionButtons={[
            { label: 'Print', action: printProgram, icon: <LocalPrintshopOutlined />},
            { label: 'Save' , action: session && session?.questions?.length > 0 ? () => setOpenFeedback(true) : saveProgram, icon: <SaveOutlined /> },

          ]}
        />
          <Typography color="green">{message}</Typography>
          {session && session?.questions?.length > 0 && (
              <Dialog
                title="Session Feedback"
                open={openFeedback}
                fullScreen={isMobile}
              >
                <DialogTitle>Session Feedback</DialogTitle>
                <DialogContent>
                  <SessionFeedbackForm submitForm={sendFeedback} onCancel={() => setOpenFeedback(false)} session={session} />
                </DialogContent>
              </Dialog>
          )}
        </div>
        <Outlet />
      </div>
    </div>
  ) : (
    <Outlet />
  );
}

export default ClientProgram;
