// Form to create a new program template
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/Coach/ProgramForm.scss';
import * as appActions from '../../../store/app/actions';
import { RootState } from '../../../store/store';
import { clientSelectors } from '../../../+store/clients/reducers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { TemplateInterface } from '../../../tsUtils/templateTypes';
import { templateActions } from '../../../+store/templates/actionTypes';
import { DesktopDatePicker } from '@mui/x-date-pickers';
interface Props {
  template: TemplateInterface;
  open: boolean;
  onClose: () => void;
}
type Option = {
  label: string;
  value: number;
};
interface TemplateForm {
  name: string;
  start: Date;
  client: number[];
}
const templateStyles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  formControls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    gap: '5px',
    marginBottom: '10px',
    minWidth: '300px',
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
};

function ProgramFromTemplateForm(props: Props) {
  const loading = useSelector((state: RootState) => state.programs.loading);
  const loaded = useSelector((state: RootState) => state.programs.loaded);
  const [open, setOpen] = React.useState(false);
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const [template, setTemplate] = React.useState<TemplateInterface | null>(null);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TemplateForm>({
    defaultValues: {
      name: `New Program`,
      start: new Date(),
      client: [],
    },
  });

  // useeffect updates the program id and name when the dialog is opened
  useEffect(() => {
    setTemplate(props.template);
  }, [props.template]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onSubmit: SubmitHandler<TemplateForm> = data => {
    dispatch(templateActions.createProgramFromTemplateRequest(template!.id, data));
    if (loaded) {
      props.onClose();
    }
  };

  function onCancel() {
    props.onClose();
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Create Program From - <Typography>{template?.name}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <div style={templateStyles.container}>
          {loading ? (
            <CircularProgress />
          ) : (
            <form style={templateStyles.form} onSubmit={handleSubmit(onSubmit)}>
              <div style={templateStyles.formControls}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      fullWidth
                      title="Program Name"
                      label="Program Name"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="start"
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Start"
                          fullWidth
                          variant="filled"
                          title="Program Start Date"
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="client"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel variant="filled">Clients</InputLabel>
                      <Select
                        {...field}
                        multiple
                        required
                        variant="filled"
                        title="Program Client"
                        onChange={e => setValue('client', e.target.value as number[])}
                        error={Boolean(errors?.client)}
                      >
                        {clients.map(client => (
                          <MenuItem key={client.id} value={client.id}>
                            {client.first_name} {client.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <div style={templateStyles.formButtons}>
                <Button onClick={() => onCancel()} variant="outlined" color="info">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Create Program
                </Button>
              </div>
            </form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ProgramFromTemplateForm;
