export const CLOSE_DIALOGS = "[app] Close Dialogs";
export const CLOSE_DIALOGS_SUCCESS = "[app] Close Dialogs Success";
export const TOGGLE_SIDEBAR = '[app] Toggle Sidebar';
export const SET_MOBILE = '[app] Set Mobile';
export const CLOSE_PREVIEW_INPUTS = '[app] Close Preview Inputs';
export const OPEN_PREVIOUS_INPUTS = '[app] Open Previous Inputs';
export const SAVE = '[app] Save';
export const SAVE_SUCCESS = '[app] Save Success';
export const ERROR = '[app] Error';
export const REMOVE_ERROR = '[app] Remove Error';
export function closeDialogs() {
  return {
    type: CLOSE_DIALOGS,
  };
}
export function closeDialogsSuccess() {
  return {
    type: CLOSE_DIALOGS_SUCCESS,
  };
}
export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}
export function setMobile(isMobile: boolean) {
  return {
    type: SET_MOBILE,
    payload: isMobile,
  };
}
export function openPreviousInputs(userId: number, index: number) {
  return {
    type: OPEN_PREVIOUS_INPUTS,
    userId,
    index,
  }
}
export function closePreviousInputs() {
  return {
    type: CLOSE_PREVIEW_INPUTS,
  };
}
export function saved(message: string = 'Saved') {
  return {
    type: SAVE,
    message,
  };
}
export function savedSuccess() {
  return {
    type: SAVE_SUCCESS,
  };
}
export function error() {
  return {
    type: ERROR,
  };
}
export function removeError() {
  return {
    type: REMOVE_ERROR,
  };
}
export interface ClosePrograms {
  type: typeof CLOSE_DIALOGS;
}
export interface CloseProgramsSuccess {
  type: typeof CLOSE_DIALOGS_SUCCESS;
}
export interface ToggleSidebar {
  type: typeof TOGGLE_SIDEBAR;
}
export interface SetMobile {
  type: typeof SET_MOBILE;
  payload: boolean;
}
export interface ClosePreviousInputs {
  type: typeof CLOSE_PREVIEW_INPUTS;
}
export interface OpenPreviousInputs {
  type: typeof OPEN_PREVIOUS_INPUTS;
  userId: number;
  index: number;
}
export interface Save {
  type: typeof SAVE;
  message: string;
}
export interface SaveSuccess {
  type: typeof SAVE_SUCCESS;
}
export interface Error {
  type: typeof ERROR;
}
export interface RemoveError {
  type: typeof REMOVE_ERROR;
}

export type AppActions = ClosePrograms | CloseProgramsSuccess | ToggleSidebar | SetMobile | ClosePreviousInputs | OpenPreviousInputs | Save | SaveSuccess | Error | RemoveError;
