import { User } from '../../tsUtils';
import * as paths from '../paths';

function getAuthHeader() {
  const token = localStorage.getItem('token');
  const authHeaders = new Headers();
  authHeaders.append('Content-Type', 'application/json');
  authHeaders.append('Authorization', `token ${token}`);
  return authHeaders;
}

export async function login(creds: {
  username: string;
  password: string;
}): Promise<{ token: string; user: User; message: any }> {
  let headers = new Headers();

  headers.append('Content-Type', 'application/json');

  return await fetch(paths.COACHLOGIN, {
    method: 'POST',
    headers,
    body: JSON.stringify(creds),
  }).then(
    response => {
      return response.json();
    },
    error => {
      return { error };
    },
  );
}

export async function logout(): Promise<{ message: string }> {
  let headers = getAuthHeader();
  return await fetch(paths.COACHLOGOUT, {
    method: 'POST',
    headers,
  }).then(
    response => {
      return response.json();
    },
    error => {
      return { error };
    },
  );
}
export async function clientLogin(creds: {
  username: string;
  password: string;
}): Promise<{ token: string; user: User; message: any }> {
  let headers = new Headers();

  headers.append('Content-Type', 'application/json');

  return await fetch(paths.CLIENTLOGIN, {
    method: 'POST',
    headers,
    body: JSON.stringify(creds),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function clientLogout(): Promise<{ message: string }> {
  let headers = getAuthHeader();
  return await fetch(paths.CLIENTLOGOUT, {
    method: 'POST',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
