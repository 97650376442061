import React, { useEffect, useState } from 'react';
import { SessionQuestion } from '../../tsUtils';

type Feedback = {
  value: string | number | null;
  update: (newValue: any, i: number, f: Feedback[]) => void;
  type: string;
  question: string;
};

export function useSessionFeedback(questions: SessionQuestion[]) {
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (questions) {
      let initial = questions.map((question, index) => {
        return {
          value: question.type !== 'scale' ? '' : null,
          update: (newValue: any, i: number, f: Feedback[]) => updateQuestion(newValue, i, f),
          type: question.type,
          question: question.question,
        };
      });
      setFeedback(initial);
    }
  }, [questions]);

  function onSubmit() {
    return {
      feedback: feedback?.map(answer =>
        typeof answer.type !== 'string' ? answer.value?.toString() : answer.value,
      ),
      comment,
    } as { feedback: string[]; comment: string };
  }
  function updateQuestion(newValue: any, i: number, oldFeedback: Feedback[]) {
    let value = oldFeedback.map((answer, index) => {
      if (i === index) {
        return { ...answer, value: newValue };
      }
      return answer;
    });
    setFeedback(value);
  }
  function clear() {}

  return { comment, setComment, onSubmit, feedback };
}
