import * as actionTypes from './actionTypes';
import { User } from '../../tsUtils';
import { ClientForm } from '../../coachApp/clients/components/ClientForm';

export const GET_CLIENTS = '[Clients] Get Clients';
export const GET_CLIENTS_SUCCESS = '[Clients] Get Clients Success';
export const GET_CLIENTS_FAIL = '[Clients] Get Clients Failure';

export const CREATE_CLIENT_REQUEST = '[Clients] Create Client Request';
export const CREATE_CLIENT_SUCCESS = '[Clients] Create Client Success';
export const CREATE_CLIENT_FAIL = '[Clients] Create Client Failure';

export const DELETE_CLIENT_REQUEST = '[Clients] Delete Client Request';
export const DELETE_CLIENT_SUCCESS = '[Clients] Delete Client Success';
export const DELETE_CLIENT_FAIL = '[Clients] Delete Client Failure';

export const UPDATE_CLIENT_REQUEST = '[Clients] Update Client Request';
export const UPDATE_CLIENT_SUCCESS = '[Clients] Update Client Success';
export const UPDATE_CLIENT_FAIL = '[Clients] Update Client Failure';

export function getClients(query: string) {
  return {
    type: actionTypes.GET_CLIENTS,
    query,
  };
}

export function getClientsSuccess(clientList: User[]) {
  return {
    type: actionTypes.GET_CLIENTS_SUCCESS,
    clientList,
  };
}
export function getClientsFail(error: any) {
  return {
    type: actionTypes.GET_CLIENTS_FAIL,
    error,
  };
}

export function createClient(client: ClientForm) {
  return {
    type: actionTypes.CREATE_CLIENT_REQUEST,
    client,
  };
}

export function updateClient(client: ClientForm, clientId: number) {
  return {
    type: actionTypes.UPDATE_CLIENT_REQUEST,
    client,
    clientId,
  };
}

export function deleteClient(id: number) {
  return {
    type: actionTypes.DELETE_CLIENT_REQUEST,
    id,
  };
}