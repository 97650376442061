import React, { Component } from "react";
import CalendarComponent from "./containers/CalendarComponent";
import '../../style/Coach/Schedule.scss';
import BaseDialog from "../programs/dialogs/BaseDialog";
import AddProgramEvents from "./forms/AddProgramEvents";
function Schedule() {
  return (
    <div className="schedule-cont">
      <div style={{width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'flex-end'}}>
        <BaseDialog buttonText="Add Program" buttonWidth={180}>
         <AddProgramEvents />
        </BaseDialog>
      </div>
      <CalendarComponent></CalendarComponent>
    </div>
  );
}

export default Schedule;
