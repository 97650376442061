import { CardInputsClass } from './exerciseCardTypes';

export interface ClientInputsInterface {
  id?: number;
  card: number;
  client: number;
  completedate: Date;
  modified: Date;
  comment: string;
  completed: boolean;
  inputs: CardInputsClass;
}

export class ClientInputsClass<ClientInputsInterface> {
  constructor(partial?: ClientInputsInterface) {
    Object.assign(this, partial);
  }
}
