import { ProgramDetailInterface, ProgramInterface } from '../../tsUtils/programTypes';
import * as paths from './paths';
import { getAuthHeader } from '../../coachApp/services/paths';
import { PaginationResults } from '../../tsUtils';

export async function clientGetPrograms(query: string | null): Promise<PaginationResults<ProgramInterface[]>> {
  let endpoint = `${paths.CLIENTPROGRAM}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function getProgram(id: number): Promise<ProgramDetailInterface> {
  let endpoint = `${paths.CLIENTPROGRAM}${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
