import * as paths from "./paths";

export async function getDefinitions() {
  let endpoint = `${paths.COACHDEFINITIONS}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
