// template reducers

import { createSlice, EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { TemplateInterface } from '../../tsUtils/templateTypes';
import { RootState } from '../../store/store';

export interface TemplateState extends EntityState<TemplateInterface> {
  loading: boolean;
  loaded: boolean;
  error: any;
  selectedTemplate: TemplateInterface | null;
  message?: string;
}

const templateAdapter = createEntityAdapter<TemplateInterface>({});

export const templateInitialState: TemplateState = templateAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  selectedTemplate: null,
});

export const templateSlice = createSlice({
  name: 'templates',
  initialState: templateInitialState,
  reducers: {
    templatesLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    templatesLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    templatesFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getTemplatesSuccess(state, action) {
      templateAdapter.addMany(state, action.payload);
    },
    getTemplateSuccess(state, action) {
      templateAdapter.upsertOne(state, action.payload);
      state.selectedTemplate = action.payload;
    },
  },
});

export const {
  templatesLoaded,
  templatesLoading,
  templatesFail,
  getTemplatesSuccess,
  getTemplateSuccess,
} = templateSlice.actions;

export const templateSelectors = templateAdapter.getSelectors<RootState>(state => state.templates);
