import React from 'react';
import { Select } from '@mui/material';

import { styled } from '@mui/system';
export const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    height: 20px;
    min-height: 0px;
    text-align: center;
    width: 100%;
    background: #eeeeee00;
  }
  & .MuiSelect-select {
    height: 20px;
    min-height: 0px;
    border: none;
    width: 100%;
    background: #eee;
    border-radius: 4px;
    text-align: center;
  }
  & .MuiOutlinedInput-input {
    min-height: 0px;
  }
  & .MuiInputBase-input {
    min-height: 0px;
    padding: 4.7px 8px 4.7px 8px;
  }
  & .MuiSelect-outlined {
    border: none;
    min-height: 0px;
    height: 20px;
    width: 100%;
    text-align: center;
  }
  & .MuiSvgIcon-root {
    right: 1px;
  }
`;
