import { Avatar, Tooltip } from '@mui/material';
import react from 'react';

interface Props {
  first: string;
  last?: string;
  size?: number;
  borderColor?: string;
  borderWidth?: number;
}

export default function UserAvatar(props: Props) {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(first: string, last?: string) {
    return {
      sx: {
        width: props.size || 40,
        height: props.size || 40,
        fontSize: props.size ? props.size / 2 : 24,
        bgcolor: stringToColor(`${first}${last && last}`),
        border: `${props.borderWidth || 2 }px solid ${props.borderColor || 'white'}`
      },
      children: `${first.charAt(0)}${last && last.charAt(0)}`,
    };
  }

  return (
    <Tooltip title={`${props.first} ${props.last}`}>
      <Avatar {...stringAvatar(props.first, props.last)} />
    </Tooltip>
  );
}
