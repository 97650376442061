import { Box } from '@mui/system';
import React from 'react';
import '../style/Preview/ClientProgram.scss';
import { Typography } from '@mui/material';
import { Day, ProgramInterface } from '../tsUtils';
import store from '../store/store';
import moment from 'moment';
import { TemplateInterface } from '../tsUtils/templateTypes';

interface Props {
  program: ProgramInterface | TemplateInterface;
  type: 'PROGRAM' | 'TEMPLATE';
}

function PreviewProgramHead(props: Props) {
  const getDate = (day: number, currentWeek: number) => {
    const weekVal = currentWeek * 7;
    const newDate = (props.program as ProgramInterface)?.start
      ? new Date((props.program as ProgramInterface).start)
      : new Date();
    newDate.setDate(newDate.getDate() + (day - newDate.getDay() + weekVal));
    const dateArr = newDate.toDateString().split(' ');
    return `${dateArr[2]} / ${dateArr[1]}`;
  };
  const getWorkoutName = (id: number) => {
    if (props.type === 'PROGRAM') return store.getState().workouts?.entities[id]?.name ?? 'unknown';
    else
      return (
        (props.program.workouts as Array<{ id: number; order: number; name: string }>).find(
          workout => workout.id === id,
        )?.name ?? 'unknown'
      );
  };
  return (
    <div className="print-head-cont">
      <Box className="print-program-head">
        <div
          style={{
            outline: '1px solid #07383F',
            gridColumn: '1/4',
            background: '#07383F',
            borderRadius: '4px 4px 0px 0px',
          }}
        >
          <Typography
            variant="button"
            fontSize="xx-large"
            style={{
              lineHeight: '65px',
              fontWeight: 'bold',
              color: 'white',
            }}
            component="div"
          >
            {props.program.name}
          </Typography>
        </div>

        <div style={{ gridRow: '2/3', gridColumn: '1/2' }}>
          <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
            Coach:
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
            {props.program.creator?.first_name
              ? props.program.creator.first_name + ' ' + props.program.creator.last_name
              : '-'}
          </Typography>
        </div>
        {props.type === 'PROGRAM' && (
          <div style={{ gridRow: '2/3', gridColumn: '2/3' }}>
            <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
              Client:
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
              {(props.program as ProgramInterface).client
                ? (props.program as ProgramInterface).client.map(
                    client => client.first_name + ' ' + client.last_name,
                  )
                : '-'}
            </Typography>
          </div>
        )}
        {props.type === 'PROGRAM' && (
          <div style={{ gridRow: '2/3', gridColumn: '3/4' }}>
            <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
              Start Date:
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
              {new Date((props.program as ProgramInterface).start).toDateString() ?? '-'}
            </Typography>
          </div>
        )}
        <div style={{ gridRow: '3/4', gridColumn: '1/2' }}>
          <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
            Quadrennial Cycle:
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
            {props.program.quadrennial ?? '-'}
          </Typography>
        </div>
        <div style={{ gridRow: '3/4', gridColumn: '2/3' }}>
          <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
            Macro Cycle:
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
            {props.program.macro ?? '-'}
          </Typography>
        </div>
        <div style={{ gridRow: '3/4', gridColumn: '3/4' }}>
          <Typography variant="button" sx={{ lineHeight: '35px' }} component="div">
            Meso Cycle:
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '25px' }} component="div">
            {props.program.meso ?? '-'}
          </Typography>
        </div>
      </Box>
      {props.program.program_config?.plan.map((week, index) => (
        <div key={index} style={{ margin: '0px 5px' }}>
          <Typography
            style={{
              margin: '0px 10px',

              textAlign: 'center',
              background: '#15998E',
              color: 'white',
            }}
            component="div"
            variant="button"
          >
            Week {index + 1}
          </Typography>
          <div className="print-program-config">
            {Object.entries(week).map((day, dayIndex) => (
              <div key={'day' + dayIndex} className="config-item">
                <div className="config-item-title">
                  <Typography fontSize="small" component="span" variant="button">{Day[dayIndex]}</Typography>
                  <span>{props.type === 'PROGRAM' && getDate(dayIndex, index)}</span>
                </div>
                {day[1].map(sess => (
                  <span key={`${sess.sessionId}_${sess.workoutId}`} className="print-config-pill">
                    <Typography fontSize="small" component="span" variant="button">{getWorkoutName(sess.workoutId)}</Typography>
                    <div>{moment(sess.dateTime).format('h:mm a')}</div>
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PreviewProgramHead;
