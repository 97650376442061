import { ExerciseBaseInterface } from '../../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';

export interface ExerciseState extends EntityState<ExerciseBaseInterface> {
  loading: boolean;
  loaded: Boolean;
  error: any;
}

const exerciseAdapter = createEntityAdapter<ExerciseBaseInterface>({});

export const exerciseInitialState: ExerciseState = exerciseAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const exerciseSlice = createSlice({
  name: 'exercises',
  initialState: exerciseInitialState,
  reducers: {
    exercisesLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    exercisesLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    exercisesFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getExercisesSuccess(state, action) {
      exerciseAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { exercisesLoading, exercisesLoaded, exercisesFail, getExercisesSuccess } =
  exerciseSlice.actions;

export const exerciseSelectors = exerciseAdapter.getSelectors<RootState>(
  state => state.definitions.exercises,
);
