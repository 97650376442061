import React, { useEffect, useState } from 'react';
import { WorkoutDetailInterface } from '../../tsUtils';
import '../../style/Preview/ClientProgram.scss';
import '../../style/Preview/ClientWorkout.scss';
import PreviewProgramHead from '../../common/PreviewProgramHead';
import PreviewWorkout from '../../common/PreviewWorkout';
import { TemplateInterface } from '../../tsUtils/templateTypes';
import { useDispatch } from 'react-redux';
interface Props {
  template: TemplateInterface;
}
const TemplatePreviewComponent = (props: Props) => {
  const [template, setTemplate] = useState({
    ...props.template,
    workouts: JSON.parse(props.template.workouts).map((workout: WorkoutDetailInterface) => {
      return { id: workout.id, order: workout.order, name: workout.name };
    }),
  });
  // dispatch get exercises action

  useEffect(() => {
    if (template.id !== props.template.id) {
      setTemplate({
        ...props.template,
        workouts: JSON.parse(props.template.workouts).map((workout: WorkoutDetailInterface) => {
          return { id: workout.id, order: workout.order, name: workout.name };
        }),
      });
    }
  }, [props.template]);

  return (
    template && (
      <div style={{minWidth: '700px'}} className="print-program">
        <PreviewProgramHead program={template} type="TEMPLATE"></PreviewProgramHead>
        {JSON.parse(props.template.workouts).map((workout: WorkoutDetailInterface) => (
          <PreviewWorkout key={workout.id} workout={workout} />
        ))}
      </div>
    )
  );
};

export default TemplatePreviewComponent;
