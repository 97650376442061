import * as actionTypes from './actionTypes';
import { User } from '../../tsUtils';
import { EventBase, EventDetails } from '../../tsUtils/eventTypes';

export const GET_EVENTS = '[Events] Get Events';
export const GET_EVENTS_SUCCESS = '[Events] Get Events Success';
export const GET_EVENTS_FAIL = '[Events] Get Events Failure';

export const CREATE_EVENT_REQUEST = '[Events] Create Event Request';
export const CREATE_EVENT_SUCCESS = '[Events] Create Event Success';
export const CREATE_EVENT_FAIL = '[Events] Create Event Failure';

export const DELETE_EVENT_REQUEST = '[Events] Delete Event Request';
export const DELETE_EVENT_SUCCESS = '[Events] Delete Event Success';
export const DELETE_EVENT_FAIL = '[Events] Delete Event Fail';

export const UPDATE_EVENT_REQUEST = '[Events] Update Event Request';
export const UPDATE_EVENT_SUCCESS = '[Events] Update Event Success';
export const UPDATE_EVENT_FAIL = '[Events] Update Event Failure';

export function getEvents(query: string) {
  return {
    type: actionTypes.GET_EVENTS,
    query,
  };
}

export function getEventsSuccess(eventList: EventBase[]) {
  return {
    type: actionTypes.GET_EVENTS_SUCCESS,
    eventList,
  };
}
export function getEventsFail(error: any) {
  return {
    type: actionTypes.GET_EVENTS_FAIL,
    error,
  };
}

export function createEvent(event: EventDetails) {
  return {
    type: actionTypes.CREATE_EVENT_REQUEST,
    event,
  };
}
export function deleteEvent(id: number) {
  return {
    type: actionTypes.DELETE_EVENT_REQUEST,
    id,
  };
}
export function updateEvent(event: EventBase) {
  return {
    type: actionTypes.UPDATE_EVENT_REQUEST,
    event,
  };
}
