import moment from "moment";
import { User, SessionDetailInterface, SessionInterface } from "../../tsUtils";
import * as paths from "./paths";
import { getAuthHeader } from "./paths";

export async function getSessions(
  query: string
): Promise<SessionDetailInterface[]> {
  let endpoint = `${paths.COACHSESSION}${query}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
    },
    (error) => {
      return { error };
    }
  );
}
export async function createSession(session: SessionInterface): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(session),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function updateSession(session: SessionInterface): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}/${session.id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(session),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      console.log(error);
      return { error };
    },
  );
}

export async function renameSession(sessionId: number, name: string): Promise<SessionInterface> {
  let endpoint = `${paths.COACHSESSION}/${sessionId}/rename`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(name),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function copySession(id: number): Promise<SessionDetailInterface> {
  let endpoint = `${paths.COACHSESSION}/${id}/copy`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function deleteSession(
  id: number,
  day: Day,
  week: number,
  config: {
    workoutId: number;
    sessionId: number;
    dateTime: string;
  },
): Promise<string> {
  let endpoint = `${paths.COACHSESSION}/${id}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ day, week, config }),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

