import {
  ExerciseCardInterface,
  SessionDetailInterface,
  SessionInterface,
  WeekConfig,
  WorkoutInterface,
} from '../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import { sessionsFail } from '../sessions/reducers';

export interface WorkoutState extends EntityState<WorkoutInterface> {
  selectedId?: number;
  loading: boolean;
  loaded: Boolean;
  error: any | null;
}

const workoutAdapter = createEntityAdapter<WorkoutInterface>({});

export const workoutInitialState: WorkoutState = workoutAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const workoutSlice = createSlice({
  name: 'workouts',
  initialState: workoutInitialState,
  reducers: {
    workoutsLoading(state: WorkoutState) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    workoutsLoaded(state: WorkoutState) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    workoutsFail(state: WorkoutState, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getWorkoutsSuccess(state: WorkoutState, action) {
      workoutAdapter.upsertMany(state, action.payload);
    },
    createWorkoutSuccess(state: WorkoutState, action) {
      workoutAdapter.upsertOne(state, action.payload);
    },
    deleteWorkoutSuccess(state: WorkoutState, action) {
      workoutAdapter.removeOne(state, action.payload);
    },
    renameWorkoutSuccess(state: WorkoutState, action: { payload: WorkoutInterface }) {
      workoutAdapter.upsertOne(state, action.payload);
    },
    setSelectedWorkoutId(state: WorkoutState, action) {
      state.selectedId = action.payload;
    },
    clearSelectedWorkout(state: WorkoutState) {
      let newState = state;
      delete newState.selectedId;
      state = newState;
    },
    addSessionSuccess(state: WorkoutState, action: { payload: SessionInterface }) {
      state.entities[action.payload.workout]?.sessions?.push({
        id: action.payload.id!,
        order: action.payload.order,
      });
    },
    removeWorkoutSession(
      state: WorkoutState,
      action: { payload: { id: number; workoutId: number } },
    ) {
      if (
        state.entities[action.payload.workoutId]?.sessions &&
        state.entities[action.payload.workoutId]?.sessions.length === 1
      ) {
        workoutAdapter.removeOne(state, action.payload.workoutId);
      } else {
        state.entities[action.payload.workoutId]?.sessions?.filter(
          session => session.id !== action.payload.id!,
        );
      }
    },

    updateWorkoutSuccess: workoutAdapter.updateOne,
  },
});

export const {
  workoutsLoading,
  workoutsLoaded,
  workoutsFail,
  setSelectedWorkoutId,
  getWorkoutsSuccess,
  createWorkoutSuccess,
  deleteWorkoutSuccess,
  updateWorkoutSuccess,
  renameWorkoutSuccess,
  addSessionSuccess,
  removeWorkoutSession,
} = workoutSlice.actions;

export const workoutSelectors = workoutAdapter.getSelectors<RootState>(state => state.workouts);

export const selectWorkoutWithSessions = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState) => state.sessions.entities,
    (state: RootState) => state.exerciseCards.entities,
    (state: RootState, workoutId: number) => workoutId,
  ],
  (workoutEntities, sessionEntities, cardEntities, workoutId) => {
    const workout = workoutEntities[workoutId];
    let sessions: SessionDetailInterface[] = [];
    if (workout) {
      sessions = workout?.sessions.reduce((a, c): SessionDetailInterface[] => {
        let session = sessionEntities[c.id];
        if (session) {
          const exerciseCards = session.exerciseCards.reduce((a, c): ExerciseCardInterface[] => {
            const card = cardEntities[c.id];
            if (card) {
              return [...a, card];
            }
            return a;
          }, [] as ExerciseCardInterface[]);
          a.push({
            ...session,
            exerciseCards,
          });
        }
        return a;
      }, [] as SessionDetailInterface[]);
    }

    return {
      id: workout?.id,
      program: workout?.program!,
      name: workout?.name!,
      order: workout?.order!,
      warmup: workout?.warmup!,
      cooldown: workout?.cooldown!,
      modified: workout?.modified!,
      sessions,
    };
  },
);

export const getSelectedWeekSessions = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState) => state.sessions.entities,
    (state: RootState, week: WeekConfig) => week,
  ],
  (workouts, sessions, week) => {
    return Object.values(week).reduce<string[]>((acc, curr) => {
      curr.forEach(programConfig => {
        let session = sessions[programConfig.sessionId];
        let workout = workouts[programConfig.workoutId];
        if (session && workout) {
          acc.push(`${workout.name}: ${session.name}`);
        }
      });
      return acc;
    }, []);
  },
);

export const getSelectedWorkout = createSelector(
  [
    (state: RootState) => state.workouts.selectedId,
    (state: RootState) => workoutSelectors.selectEntities(state),
  ],
  (selectedId, workouts) => (selectedId ? workouts[selectedId] : null),
);

export const selectWorkoutsById = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState, ids: { id: number; order: number }[]) => ids,
  ],
  (workoutEntity, ids) => {
    const workouts = ids.reduce<WorkoutInterface[]>((a, id) => {
      const workout = workoutEntity[id.id];
      if (workout) {
        a.push(workout);
      }
      return a;
    }, []);

    return workouts;
  },
);
