import { call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';
import * as API from '../../coachApp/services/accountAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import * as actionTypes from './actionTypes';
import * as types from './types';

export function* getAccountDetails(action: types.GetAccountDetailsRequest): any {
  try {
    const response: SagaReturnType<typeof API.getAccountDetails> = yield call(API.getAccountDetails);
    yield put(actionTypes.getAccountSuccess(response));
  } catch (error) {
    
    yield put(actionTypes.getAccountFail(error));
  }
}

export function* accountDetailsSaga() {
  yield takeLatest(actionTypes.GET_ACCOUNT, getAccountDetails);
}
