import { ProgramBaseInterface } from '../../tsUtils';
import { TemplateInterface } from '../../tsUtils/templateTypes';
import * as paths from './paths';

export async function getTemplates(query: string): Promise<TemplateInterface[]> {
  let endpoint = `${paths.COACHTEMPLATES}${query}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function getTemplate(id: number): Promise<TemplateInterface> {
  let endpoint = `${paths.COACHTEMPLATES}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function removeTemplate(id: number): Promise<TemplateInterface | string> {
  let endpoint = `${paths.COACHTEMPLATES}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'DELETE',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function createProgramFromTemplate(
  templateId: number,
  data: { start: Date; name: string; client: number[] },
): Promise<ProgramBaseInterface> {
  let endpoint = `${paths.COACHTEMPLATES}/${templateId}/program`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
