import { ExerciseBaseInterface } from '../../../tsUtils';

export const GET_EXERCISES = '[Definitions] Get Exercises';
export const GET_EXERCISES_SUCCESS = '[Definitions] Get Exercises Success';
export const GET_EXERCISES_FAIL = '[Definitions] Get Exercises Failure';

export function getExercises() {
  return {
    type: GET_EXERCISES,
  };
}

export function getExercisesSuccess(exercises: ExerciseBaseInterface[]) {
  return {
    type: GET_EXERCISES_SUCCESS,
    exercises,
  };
}
export function getExercisesFail(error: any) {
  return {
    type: GET_EXERCISES_FAIL,
    error,
  };
}
