import { Box } from '@mui/system';

import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from '../../../store/store';
import { Day, ProgramConfigClass, ProgramConfigSession } from '../../../tsUtils';
import '../../../style/Coach/Program.scss';
import { Button, CircularProgress, Fab, LinearProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionSlice } from '../../../+store/sessions/reducers';
import { workoutSlice } from '../../../+store/workouts/reducers';
import moment from 'moment';

interface Props {}
const main = {
  width: 'calc(100% - 20px)',
  maxWidth: '1200px',
  display: 'grid',
  gridTemplateColumn: '33% 33% 33%',
  gridTemplateRow: '50% 50%',
  borderRadius: '6px',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.3)',
  padding: '10px 20px',
  margin: '15px 20px 15px 20px',
  gap: '10px',
};

function ClientProgramHead(props: Props) {
  const { programId, workoutId, sessionId } = useParams();
  const dispatch = useDispatch();
  const program = useSelector((state: RootState) => state.programs.selectedProgram);
  const [selectedValue, setSelectedValue] = useState<{
    sessionId: number;
    workoutId: number;
  } | null>();
  const navigate = useNavigate();
  useEffect(() => {
    if (workoutId && sessionId) {
      setSelectedValue({ sessionId: Number(sessionId), workoutId: Number(workoutId) });
    }
  }, []);

  const getDate = (day: number, weekNo: number) => {
    if (program) {
      const weekVal = weekNo * 7;
      const newDate = new Date(program.start);
      newDate.setDate(newDate.getDate() + (day - newDate.getDay() + weekVal));
      const dateArr = newDate.toDateString().split(' ');
      return `${dateArr[2]} / ${dateArr[1]}`;
    }
  };
  const getWorkoutName = (id: number) => store.getState().workouts?.entities[id]?.name ?? 'unknown';
  const handleChangeSession = (value?: ProgramConfigSession) => {
    if (value) {
      dispatch(sessionSlice.actions.setSelectedSessionId(value.sessionId));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(value.workoutId));
      setSelectedValue({ sessionId: value.sessionId, workoutId: value.workoutId });
      navigate(`/client/programs/${programId}/${value.workoutId}/${value.sessionId}`);
    } else {
      dispatch(sessionSlice.actions.setSelectedSessionId(null));
      dispatch(workoutSlice.actions.setSelectedWorkoutId(null));
      setSelectedValue(null);
      navigate(`/client/programs/${programId}`);
    }
  };
// prevent propagation of mouse events to parent
  const stopProp = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  // prevent propagation of touch events to parent
  const stopProp2 = (e: React.TouchEvent) => {
    e.stopPropagation();
  };
  return program ? (
    <>
      <Box style={main} className="light">
        <div
          style={{
            gridRow: '1/2',
            gridColumn: '1/4',
            display: 'flex',
            alignItems: 'start',
            borderBottom: '1px solid #ccc',
            textTransform: 'uppercase',
          }}
        >
          <Typography variant="h5"  component="span">
            {program.name}
          </Typography>
        </div>

        <div
          style={{
            gridRow: '2/3',
            gridColumn: '1/2',
          }}
        >
          <Typography variant="button" component="div">
            Macro Cycle:
          </Typography>
          <Typography variant="body1" fontSize="small" component="div">
            {program.macro}
          </Typography>
        </div>
        <div
          style={{
            gridRow: '2/3',
            gridColumn: '2/3',
          }}
        >
          <Typography variant="button" component="div">
            Meso Cycle:
          </Typography>
          <Typography variant="body1" fontSize="small" component="div">
            {program.meso}
          </Typography>
        </div>
        <div
          style={{
            gridRow: '2/3',
            gridColumn: '3/4',
          }}
        >
          <Typography variant="button" component="div">
            Start Date:
          </Typography>
          <Typography variant="body1" fontSize="small" component="div">
            {new Date(program.start).toDateString()}
          </Typography>
        </div>
      </Box>

      {program.program_config.plan.map((week, index) => (
        <div style={{ padding: '0px 10px', width: '100%' }} key={index}>
          <Typography variant="h6" component="span">
            Week: {index + 1}
          </Typography>
          <div onTouchStart={stopProp2} onTouchMove={stopProp2} onMouseDown={stopProp} onMouseMove={stopProp} className="program-config">
            {Object.entries(week).map((day, dayIndex) => (
              <div key={`day${dayIndex}`} className="config-item">
                <div className="config-item-title">
                  <Typography variant="button" component="span" >{Day[dayIndex]}</Typography>
                  <span>{getDate(dayIndex, index)}</span>
                </div>
                {day[1].map((session, sessionIndex) => (
                  <span key={`session${sessionIndex}`} className="config-item-pill-cont">
                    <button
                      onClick={() => handleChangeSession(session)}
                      color="primary"
                      key={`${session.sessionId}_${session.workoutId}`}
                      className="config-item-pill button"
                    >
                      <Typography variant="button" component="span">{getWorkoutName(session.workoutId)}</Typography>
                      <div>{moment(session.dateTime).format('h:mm a')}</div>
                    </button>
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  ) : (
    <LinearProgress />
  );
}

export default ClientProgramHead;
