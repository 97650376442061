import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as appActions from '../../../store/app/actions';
import { RootState } from '../../../store/store';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@mui/material';
import { SessionFeedbackRequest, SessionInterface } from '../../../tsUtils';
import { useSessionFeedback } from '../../hooks/useSessionFeedback';
import { sessionActions } from '../../../+store/sessions/actionTypes';
interface Props {
  session: SessionInterface;
  submitForm: (data: SessionFeedbackRequest) => void;
  onCancel: () => void;
}

interface SessionFeedbackForm {
  comment: string;
  feedback: any[];
}

type Option = {};
const formStyle: React.CSSProperties = {
  minWidth: '300px',
  maxHeight: '90%',
};
const formQuestions: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '270px',
  padding: 5,
  gap: 15,
  overflow: 'auto',
};
function SessionFeedbackForm(props: Props) {
  const user = useSelector((state: RootState) => state.auth.user);
  const { comment, setComment, feedback, onSubmit } = useSessionFeedback(props.session.questions);
  const dispatch = useDispatch();

  useEffect(() => {}, [props.session]);

  function onFormSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    let data = onSubmit();
    props.submitForm({ ...data, sessionId: props.session.id });
    dispatch(appActions.closeDialogs());
  }

  return (
    <form onSubmit={onFormSubmit} style={formStyle}>
      <div style={formQuestions}>
        {feedback &&
          feedback.map((question, index) => {
            switch (question.type) {
              case 'string':
                return (
                  <TextField
                    multiline
                    minRows={2}
                    onChange={event => question.update(event.target.value, index, feedback)}
                    label={question.question}
                    value={question.value}
                    type="text"
                    key={index}
                    fullWidth
                    variant="filled"
                  />
                );
              case 'number':
                return (
                  <TextField
                    fullWidth
                    onChange={event => question.update(event.target.value, index, feedback)}
                    label={question.question}
                    value={question.value}
                    type="number"
                    key={index}
                    variant="filled"
                  />
                );
              case 'boolean':
                return (
                  <FormControl key={index} fullWidth>
                    <InputLabel>{question.question}</InputLabel>
                    <Select
                      onChange={event => question.update(event.target.value, index, feedback)}
                      value={question.value}
                      variant="filled"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                );
              case 'scale':
                return (
                  <FormControl key={index} fullWidth sx={{ width: '95%' }}>
                    <InputLabel>{question.question}</InputLabel>
                    <Slider
                      aria-label="Temperature"
                      step={1}
                      valueLabelDisplay="auto"
                      marks
                      onChange={(event, value) => question.update(value, index, feedback)}
                      value={question.value as number}
                      getAriaValueText={value => value.toString()}
                      min={1}
                      max={10}
                    />
                  </FormControl>
                );
            }
          })}
        <TextField
          multiline
          minRows={2}
          label={props.session.questions.length > 0 ? 'Other Comments' : 'Session Comments'}
          value={comment}
          onChange={event => setComment(event.target.value)}
          type="text"
          fullWidth
          variant="filled"
        />
      </div>

      <div className="form-buttons">
        <Button onClick={() => props.onCancel()} variant="outlined" color="info">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>
    </form>
  );
}

export default SessionFeedbackForm;
