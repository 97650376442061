import * as actionTypes from "../actionTypes";

import { AuthAction, AuthState } from './types';


export const authInitialState: AuthState = {
  loading: false,
  loaded: false,
};

export default function authReducer(state = authInitialState, action: AuthAction): AuthState {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.AUTH_SUCCESS:
      const date = new Date(new Date().getTime() + 3600 * 72 * 1000);
      localStorage.setItem('token', action.token);
      localStorage.setItem('expiration', date.toISOString());
      localStorage.setItem('user', JSON.stringify(action.user));
      return {
        ...state,
        token: action.token,
        user: action.user,
        loading: false,
        loaded: true,
        message: action.message,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOGOUT_SUCCESS:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
      return {
        ...state,
        loading: false,
        loaded: true,
        user: undefined,
        message: action.message,
        token: undefined,
      };
    default:
      return state;
  }
}
