import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExerciseBaseInterface, ExerciseInterface } from '../../../tsUtils';
import { RootState } from '../../../store/store';
import { ExerciseCategory } from '../../../tsUtils';
import { getExerciseSearch } from '../../services/exercisesAPI';
import { Dictionary, debounce } from 'lodash';
import {
  RadioGroup,
  Select,
  MenuItem,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  DialogContent,
  Dialog,
  LinearProgress,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledInput } from '../../../common/StyledInput';
export interface IExerciseSearchProps {
  selectedExercise?: ExerciseBaseInterface;
  onSelectExercise: (exercise: ExerciseBaseInterface) => void;
  cardIndex: number;
}
function SearchResult({
  exercise,
  onSelectExercise,
  styleDict,
}: {
  exercise: ExerciseInterface;
  onSelectExercise: (exercise: ExerciseInterface) => void;
  styleDict: Dictionary<React.CSSProperties>;
}) {
  return (
    <div key={exercise.id} style={styleDict.exerciseSearch__result}>
      <div style={styleDict.exerciseSearch__result__name}>{exercise.name}</div>
      <div style={styleDict.exerciseSearch__result__select}>
        <Button onClick={() => onSelectExercise(exercise)}>Select</Button>
      </div>
    </div>
  );
}
const searchStyle: Dictionary<React.CSSProperties> = {
  exerciseSearch: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '10px',
  },
  exerciseSearch__input: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  search_input: {
    width: '100%',
    height: '30px',
  },
  exerciseSearch__results: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    boxShadow: 'inset 0px 0px 12px 0px rgba(0,0,0,0.25)',
    padding: '10px',
    height: '100%',
  },
  exerciseSearch__result: {
    display: 'grid',
    gridTemplateColumns: '1fr 80px',
    width: '100%',
    alignItems: 'center'
  },
  exerciseSearch__result__name: {
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '14px',
  },
  exerciseSearch__result__select: {
    textAlign: 'right',

  },
  exerciseSearch__result__search: {
    width: 'calc(50% - 5px)',
    minWidth: '280px',
  },
  exerciseSearch__result__menu: {
    width: 'calc(50% - 5px)',
    minWidth: '280px',
  },
  exerciseSearch__list: {
    display: 'grid',
    width: '100%',
    maxWidth: '600px',
    height: '400px',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: ' 100%',
    padding: '10px 15px 10px 15px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    left: '10px',
    borderRadius: '5px',
  },
  exerciseSearch__list__header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignContent: 'start',
    alignItems: 'start',
    paddingTop: '10px',
    gap: '10px',
    flexWrap: 'wrap',
    height: 'min-content',
    maxHeight: '115px',
  },
};
let timer: any;

export default function ExerciseSearch(props: IExerciseSearchProps) {
  const [selectedExercise, setSelectedExercise] = React.useState<ExerciseBaseInterface | undefined>(
    props.selectedExercise,
  );
  const [search, setSearch] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [exercises, setFilteredExercises] = React.useState<ExerciseInterface[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [pagination, setPagination] = React.useState<{
    count: number;
    next: string;
    previous: string;
  } | null>(null);

  React.useEffect(() => {
    if (open) {
      initSearch(search, category);
    }
  }, [search, category]);

  React.useEffect(() => {
    if (selectedExercise) {
      setSearch(selectedExercise.name);
    }
  }, [selectedExercise]);

  React.useEffect(() => {
    if (props.selectedExercise) {
      setSelectedExercise(props.selectedExercise);
    }
  }, [props.selectedExercise]);

  function handleSelectExercise(exercise: ExerciseInterface) {
    setSelectedExercise({ id: exercise.id, name: exercise.name });
    props.onSelectExercise({ id: exercise.id, name: exercise.name });
    setOpen(false);
  }

  function handleOpen() {
    initSearch(search, category);
    setOpen(true);
  }

  function handleSearch(searchStr: string, categoryStr: string) {
    setLoading(true);
    //async api to get exercises based on search and category
    getExerciseSearch(buildQueryString(searchStr, categoryStr))
      .then(response => {
        const { results, ...pagination } = { ...response };
        setFilteredExercises(results);
        setPagination(pagination);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }
  function initSearch(searchStr: string, categoryStr: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleSearch(searchStr, categoryStr);
    }, 1000);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClear() {
    setSearch('');
  }

  // build query string from search and category
  function buildQueryString(searchStr: string, categoryStr: string) {
    let queryString = '';
    if (searchStr?.length > 0) {
      queryString += `search=${searchStr}`;
    }
    if (categoryStr?.length > 0) {
      if (queryString.length > 0) {
        queryString += '&';
      }
      queryString += `category=${categoryStr}`;
    }
    return queryString ? '?' + queryString : '';
  }
  // infinite scroll
  function handleScroll(e: any) {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight && !loading) {
      // select container

      if (pagination?.next) {
        setLoading(true);
        let searchString = buildQueryString(search, category);
        let page = pagination.next.split('?')[1];
        let queryString = searchString ? searchString + '&' + page : '?' + page;
        getExerciseSearch(queryString)
          .then(response => {
            const { results, ...pagination } = { ...response };
            setFilteredExercises([...exercises, ...results]);
            setPagination(pagination);
            setLoading(false);
            const resultsContainer = document.getElementById(`${props.cardIndex}search-results`);
            if (resultsContainer) {
              const currentScroll = scrollTop;
              setTimeout(() => {
                resultsContainer.scrollTo(0, currentScroll - 1);
              }, 100);
            }
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      }
    }
  }

  return (
    <div style={searchStyle.exerciseSearch}>
      <StyledInput
        type="text"
        onClick={handleOpen}
        style={searchStyle.search_input}
        placeholder="Search for an exercise"
        value={selectedExercise?.name || ''}
        onChange={e => setSearch(e.target.value as string)}
      />

      <Dialog open={open}>
        <DialogTitle component="div" variant="h6" align="left" sx={{width: '100%', textTransform:'uppercase', paddingBottom:'0px'}} color="#555555" >
          Select Exercise</DialogTitle>
        <DialogContent sx={searchStyle.exerciseSearch__list}>
          
            <div
              style={searchStyle.exerciseSearch__list__header}
            >
              <TextField
                size="small"
                variant="filled"
                sx={searchStyle.exerciseSearch__result__search}
                value={search || ''}
                onChange={e => setSearch(e.target.value as string)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={handleClear} position="end">
                      <CloseIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl sx={searchStyle.exerciseSearch__result__menu}>
                <InputLabel id="demo-simple-select-label">Exercise Category</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="filled"
                  value={category}
                  onChange={e => setCategory(e.target.value as string)}
                >
                  <MenuItem value="">All</MenuItem>
                  {Object.values(ExerciseCategory).map((val: string) => {
                    return (
                      <MenuItem key={val} value={val}>
                        <span style={{ textTransform: 'capitalize' }}>{val}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
           
          

          <div
            onScroll={handleScroll}
            id={`${props.cardIndex}search-results`}
            style={searchStyle.exerciseSearch__results}
          >
            {loading ? (
              <LinearProgress />
            ) : exercises.length > 0 ? (
              exercises.map((exercise: ExerciseInterface) => {
                return (
                  // SearchResult
                  <span key={'key' + exercise.id}>
                  <SearchResult
                    
                    exercise={exercise}
                    onSelectExercise={handleSelectExercise}
                    styleDict={searchStyle}
                  />
                  <hr
                      style={{
                          margin: "0px",
                          border: "solid 1px rgb(210, 210, 210)",
                          width: '100%',

                      }}
                  />
                  </span>
                  
                );
              })
            ) : (
              <div style={{ textAlign: 'center' }}>No exercises found</div>
            )}
          </div>
          <div style={{width: '100%'}}>
          <Button onClick={ handleClose } variant="outlined" color="info">Cancel</Button>
          </div>
          
        </DialogContent>
      </Dialog>
    </div>
  );
}
