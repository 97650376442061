import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../ClientStyle/ClientCard.scss';
import {openPreviousInputs} from '../../../store/app/actions';
import { Button, CircularProgress, Typography } from '@mui/material';
import ClientCardInputs from './ClientCardInputs';
import { RootState } from '../../../store/store';
import { exerciseCardSelectors, selectClientInputs, selectPreviousClientInputs } from '../../../+store/exerciseCards/reducers';
import RestTimerDialog from '../dialogs/RestTimer';
import { useCardTotals } from '../../../common/hooks/useCardTotals';

interface Props {
  index: number;
  id: number;
  userId: number;
}

function ClientExerciseCard(props: Props) {
  const cardDetails = useSelector((state: RootState) =>
    exerciseCardSelectors.selectById(state, props.id),
  );
  const clientInputs = useSelector((state: RootState) =>
    selectClientInputs(state, props.id, props.userId),
  );
  const dispatch = useDispatch();
  const [totals, updateTotals, totalsLoading] = useCardTotals();
  const previousInputs = useSelector((state: RootState) =>
    selectPreviousClientInputs(state, props.userId, props.index),
  );

  function showPrevious() {
    dispatch(openPreviousInputs(props.userId, props.index));
  }

  useEffect(() => {
    if (cardDetails && clientInputs) {
      updateTotals(cardDetails, clientInputs.inputs);
    }
  }, [cardDetails, clientInputs]);

  return cardDetails ? (
    <div className="client-card">
      <div className="cardHead">
        <div className="head_item">
          <span className="cardNo">{cardDetails.card_no}</span>
        </div>
        <div className="head_item">
            <Typography variant="button" sx={{ lineHeight: 1, fontSize: '.95em' }} component="span">
              {cardDetails.exercise?.name ?? cardDetails.userexercise ?? 'No exercise set'}
            </Typography>
        </div>
      </div>
      <div className="variation-cont">
        <div className="variation">
          <Typography variant="button"component="span">
            Variation 1:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.var1 !== '' ? cardDetails.var1 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Variation 2:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.var2 !== '' ? cardDetails.var2 : '-'}
          </Typography>
        </div>
        <div className="variation">
          <Typography variant="button" component="span">
            Equipment 1:
          </Typography>
          <Typography variant="body1"  >
            {cardDetails.eqi1 !== '' ? cardDetails.eqi1 : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Equipment 2:
          </Typography>
          <Typography variant="body1"  >
            {cardDetails.eqi2 !== '' ? cardDetails.eqi2 : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Tempo:
          </Typography>
          <Typography variant="body1" >
            {cardDetails.tempo !== '' ? cardDetails.tempo : '-'}
          </Typography>
        </div>

        <div className="variation">
          <Typography variant="button" component="span">
            Rest:
          </Typography>
          {cardDetails.rest !== '' ? 
            <RestTimerDialog rest={cardDetails.rest} /> : 
            <Typography variant="body1" >
            -
            </Typography>
          }
        </div>
      </div>
      {
        previousInputs && (
      <div >
        <Button variant="outlined" color="primary" onClick={showPrevious} fullWidth size='small' >
          Previous Inputs
        </Button>
      </div>
        )
      }
      <div>
        {clientInputs ? (
          <ClientCardInputs
            cardIndex={props.index}
            inputs={clientInputs}
            prescribed={cardDetails.inputs}
          />
        ) : (
          <div>No client inputs</div>
        )}
      </div>
      <div className="variation-cont">
        { 
        !totalsLoading ?
        Object.entries(totals).map(([key, value]: [string, string], index) => {
          if (value && !value.includes('NaN')) {
            return (
              <div key={cardDetails.id + key} className="variation">
                <Typography variant="button" component="span">
                  {key}:
                </Typography>
                <Typography variant="body1">
                  {value}
                </Typography>
              </div>
            );
            }
        }) :
        (
          <CircularProgress />
        )
        }
      </div>
    </div>
  ) : (
    <div className="card">not found</div>
  );
}

export default ClientExerciseCard;
