export enum Total {
    RVOL = 'Rep Volume', // total rep volume
    LVOL = 'Load Volume', // total amount of weight lifted
    TUT = 'Time Under Tension', // total time under tension in seconds from tempo and reps
    TIME = 'Work Time', // total time in seconds
    SETS = 'Sets', // total sets
    REST = 'Rest', // total rest in seconds
    LENGTH = 'Length', // total work + rest in seconds
    ONERM = '1RM estimate', // 1 rep max estimate
}

export const TotalValueUnits = {
    [Total.RVOL]: 'number',
    [Total.LVOL]: ' kg',
    [Total.TUT]: ' secs',
    [Total.TIME]: ' secs',
    [Total.SETS]: 'number',
    [Total.REST]: ' secs',
    [Total.LENGTH]: ' secs',
    [Total.ONERM]: ' kg',
};

export interface CardTotals {
    [key: string]: string;
}