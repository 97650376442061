import { ClientInputsInterface } from './clientInputsTypes';
import { ExerciseBaseInterface } from './exerciseTypes';

export class InputSetsClass {
  public values1: number | string | null = '';
  public values2: number | string | null = '';
  public values3: number | string | null = '';
  public values4: number | string | null = '';
  constructor(partial?: Partial<InputSetsClass>) {
    Object.assign(this, partial);
  }
}
type InputTypes = {
  input1: number | null;
  input2: number | null;
  input3: number | null;
  input4: number | null;
};
export class CardInputsClass {
  public input_types: InputTypes = {
    input1: null,
    input2: null,
    input3: null,
    input4: null,
  };
  public sets: InputSetsClass[] = [new InputSetsClass()];
  constructor(partial?: Partial<CardInputsClass>) {
    Object.assign(this, partial);
  }
}
export interface ExerciseCardInterface {
  id?: number;
  session: number;
  workout: number;
  exercise: ExerciseBaseInterface | null;
  userexercise?: string;
  card_no: string;
  order: number;
  var1: string;
  var2: string;
  eqi1: string;
  eqi2: string;
  rest: string;
  tempo: string;
  inputs: CardInputsClass;
  comment: string;
  clientinputs?: ClientInputsInterface[];
}
export class ExerciseCardClass {
  public card_no: string | null = 'A1';
  public order: number | null = 0;
  public var1: string | null = '';
  public var2: string | null = '';
  public eqi1: string | null = '';
  public eqi2: string | null = '';
  public rest: string | null = '00:00:00';
  public tempo: string = '2/0/1/0';
  public inputs: CardInputsClass | null = new CardInputsClass();
  public comment: string | null = '';
  public session: number | null = null;
  public workout: number | null = null;
  public exercise: ExerciseBaseInterface | null = null;
  public id?: number;
  public userexercise?: string;
  public clientinputs?: ClientInputsInterface[];

  constructor(partial?: Partial<ExerciseCardClass>) {
    Object.assign(this, partial);
  }
}
