// ExerciseCards

import { ExerciseBaseInterface, ExerciseCardClass, ExerciseCardInterface } from '../../tsUtils';
import { ClientInputsInterface } from '../../tsUtils/clientInputsTypes';

export const actionTypes = {
  // List ExerciseCard
  GET_EXERCISE_CARDS: '[ExerciseCards] Get ExerciseCard',
  GET_EXERCISE_CARDS_SUCCESS: '[ExerciseCards] Get ExerciseCard Success',
  GET_EXERCISE_CARDS_FAIL: '[ExerciseCards] Get ExerciseCard Failure',
  // Update ExerciseCard
  UPDATE_EXERCISE_CARD_REQUEST: '[ExerciseCards] Update ExerciseCard Request',
  UPDATE_EXERCISE_CARD_SUCCESS: '[ExerciseCards] Update ExerciseCard Success',
  UPDATE_EXERCISE_CARD_FAIL: '[ExerciseCards] Update ExerciseCard Failure',

  UPDATE_EXERCISE_CARD_EXERCISE_REQUEST: '[ExerciseCards] Update ExerciseCard Exercise Request',
  UPDATE_EXERCISE_CARD_EXERCISE_SUCCESS: '[ExerciseCards] Update ExerciseCard Exercise Success',
  UPDATE_EXERCISE_CARD_EXERCISE_FAIL: '[ExerciseCards] Update ExerciseCard Exercise Failure',

  UPDATE_CLIENT_INPUTS_REQUEST: '[ExerciseCards] Update Client Inputs Request',
  UPDATE_CLIENT_INPUTS_SUCCESS: '[ExerciseCards] Update Client Inputs Success',
  UPDATE_CLIENT_INPUTS_FAIL: '[ExerciseCards] Update Client Inputs Failure',

  CREATE_EXERCISE_CARD_REQUEST: '[ExerciseCards] Create ExerciseCard Request',
  CREATE_EXERCISE_CARD_SUCCESS: '[ExerciseCards] Create ExerciseCard Success',
  CREATE_EXERCISE_CARD_FAIL: '[ExerciseCards] Create ExerciseCard Failure',

  CLEAR_EXERCISE_CARD: '[ExerciseCards] Clear ExerciseCard',
  CLEAR_EXERCISE_CARD_SUCCESS: '[ExerciseCards] Clear ExerciseCard Success',
  CLEAR_EXERCISE_CARD_FAIL: '[ExerciseCards] Clear ExerciseCard Failure',

  DELETE_EXERCISE_CARD_REQUEST: '[ExerciseCards] Delete ExerciseCard Request',
  DELETE_EXERCISE_CARD_SUCCESS: '[ExerciseCards] Delete ExerciseCard Success',
  DELETE_EXERCISE_CARD_FAIL: '[ExerciseCards] Delete ExerciseCard Fail',
};

export const exerciseCardActions = {
  getExerciseCards: (query: string) => {
    return {
      type: actionTypes.GET_EXERCISE_CARDS,
      query,
    };
  },
  updateExerciseCard: (exerciseCard: ExerciseCardInterface) => {
    return {
      type: actionTypes.UPDATE_EXERCISE_CARD_REQUEST,
      exerciseCard,
    };
  },
  updateExerciseCardExercise: (exercise: ExerciseBaseInterface, cardId: number) => {
    return {
      type: actionTypes.UPDATE_EXERCISE_CARD_EXERCISE_REQUEST,
      exercise,
      cardId,
    };
  },
  updateClientInputsCard: (cardId: number, inputs: ClientInputsInterface) => {
    return {
      type: actionTypes.UPDATE_CLIENT_INPUTS_REQUEST,
      cardId,
      inputs,
    };
  },
  createExerciseCard: (exerciseCard: ExerciseCardClass) => {
    return {
      type: actionTypes.CREATE_EXERCISE_CARD_REQUEST,
      exerciseCard,
    };
  },
  deleteExerciseCard: (id: number) => {
    return {
      type: actionTypes.DELETE_EXERCISE_CARD_REQUEST,
      id,
    };
  },
};
