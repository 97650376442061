import { ExerciseCardInterface, InputSetsClass } from '.';
import { Total } from './totalsTypes';

export function newCard(card: ExerciseCardInterface) {}
export function newSet(set?: InputSetsClass) {
  if (!set) {
    return new InputSetsClass({
      values1: '',
      values2: '',
      values3: '',
      values4: '',
    });
  }
  return new InputSetsClass({
    values1: set.values1,
    values2: set.values2,
    values3: set.values3,
    values4: set.values4,
  });
}

export const InitialTotals = {
  [Total.RVOL]: '',
  [Total.LVOL]: '',
  [Total.TUT]: '',
  [Total.TIME]: '',
  [Total.SETS]: '',
  [Total.REST]: '',
  [Total.LENGTH]: '',
  [Total.ONERM]: '',
};
