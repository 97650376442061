import React, { useEffect, useState } from 'react';
import { Definition, CardInputsClass } from '../../../tsUtils';
import { MenuItem, Grid, Avatar, Typography } from '@mui/material';
import '../../../style/Coach/Sets.scss';
import store from '../../../store/store';
import { StyledInput } from '../../../common/StyledInput';

interface Props {
  cardIndex: number;
  inputOptions: Definition[];
  inputs: CardInputsClass;
}

function ClientInputs(props: Props) {
  const [sets, setSets] = useState(props.inputs.sets);
  useEffect(() => {
    setSets(props.inputs.sets);
  }, [props.inputs]);

  const options = props.inputOptions.map(opt => (
    <MenuItem key={opt.id} sx={{ fontSize: 'small' }} title={opt.name} value={opt.id}>
      {opt.name}
    </MenuItem>
  ));
  const getDefinitionName = (type?: number | null) => {
    let input = type && store.getState().definitions.inputs.entities[type];
    let id = input ? input.name : undefined;
    return !input ? (
      <Typography variant="body1" component="span">
        -
      </Typography>
    ) : (
      <>
        <Typography variant='button'>{input.name.length < 8 ? input.name : input.initials}</Typography>
      </>
    );
  };

  const rows = sets.map((set, index) => (
    <Grid container key={index} sx={{ display: 'flex', width: '100%' }}>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar sx={{ width: 24, height: 24, fontSize: 'small', backgroundColor: '#15998E' }}>
          {index + 1}
        </Avatar>
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        <StyledInput
          className="setInput"
          variant="outlined"
          size="small"
          fullWidth
          value={set.values1}
          disabled
        />
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        <StyledInput
          className="setInput"
          variant="outlined"
          size="small"
          fullWidth
          value={set.values2}
          disabled
        />
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        <StyledInput
          className="setInput"
          variant="outlined"
          size="small"
          fullWidth
          value={set.values3}
          disabled
        />
      </Grid>
      <Grid item xs={2.75} className="inputCell">
        <StyledInput
          className="setInput"
          variant="outlined"
          size="small"
          fullWidth
          value={set.values4}
          disabled
        />
      </Grid>
    </Grid>
  ));

  return (
    <div className="cardInputs">
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.input_types.input1)}
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.input_types.input2)}
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.input_types.input3)}
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{
            flex: 1,
            padding: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getDefinitionName(props.inputs.input_types.input4)}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid container sx={{ width: '100%' }}>
        {rows}
      </Grid>
    </div>
  );
}

export default ClientInputs;
