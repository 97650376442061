import * as actionTypes from "../actionTypes";
// import * as paths from '../paths';
// import { getClients } from '../definitions/actions';
import { User } from "../../tsUtils";

export const login = (creds: { username: string; password: string }) => {
  return {
    type: actionTypes.LOGIN,
    creds,
  };
};
export const clientLogin = (creds: { username: string; password: string }) => {
  return {
    type: actionTypes.CLIENT_LOGIN,
    creds,
  };
};
export const authSuccess = (response: { token: string; user: User; message: string }) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: response.token,
    user: response.user,
    message: response.message,
  };
};

export const loginFail = (error: any) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const logoutSuccess = (message: string) => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    message,
  };
};

export const checkAuthState = () => {
  return {
    type: actionTypes.CHECK_AUTH_STATE,
  };
};
export const updateAuthTimeout = () => {
  return {
    type: actionTypes.UPDATE_AUTH_TIMEOUT,
  };
};

