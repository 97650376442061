import { useState } from 'react';
import { CardInputsClass, ExerciseCardClass } from '../../tsUtils/exerciseCardTypes';
import { InitialTotals } from '../../tsUtils/defaults';
import { Total, CardTotals, TotalValueUnits} from '../../tsUtils/totalsTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Definition } from '../../tsUtils';
import { Dictionary } from '@reduxjs/toolkit';

export interface CardTotalsInput {
  card: ExerciseCardClass;
  inputs: CardInputsClass;
}
export const CalculateTotal = (inputs: Array<number[]>) => {
  let sum = 0;
  for (let i = 0; i < inputs[0].length; i++) {
    let product = 1;
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j][i]) {
        product *= Number(inputs[j][i]) || 0;
      }
    }
    sum += product;
  };
  return sum;
}

class ValuesGetter {
  rest: number = 0;
  tempo: number = 0;
  inputValues: {[key: string]: number[]};
  sets: number = 0;
  constructor(rest: string | null, tempo: string, inputs: CardInputsClass, inputTypes: Dictionary<Definition>) {
    this.rest = rest?.split(':').map((interval, i) => Number(interval) * Math.pow(60, (2 - i))).reduce((acc, val) => acc + val, 0) || 0;
    this.tempo = tempo.split('/')?.reduce((acc, val) => {
      const interval = parseInt(val) || 0;
      return acc + interval
    }, 0);
    this.sets = inputs.sets.length;
    this.inputValues = inputs.sets.reduce((acc, set) => {
        Object.values(set).forEach((val: any, i:number) => {
          const inputType: Definition | undefined = inputTypes[Object.values(inputs.input_types)[i]!];
          if(!inputType) {
            return acc;
          }
          else if(inputType.name in acc) {
            acc[inputType.name].push(val);
          } else {
            acc[inputType.name] = [val];
          }
        });
        return acc;
    }, {} as {[key: string]: number[]});
  }
}

export const useCardTotals = (): [CardTotals, (card: ExerciseCardClass, input: CardInputsClass) => void, boolean] => {
  const [totals, setTotals] = useState<CardTotals>(InitialTotals);
  const inputTypes = useSelector((state: RootState) => state.definitions.inputs.entities);
  const [loading, setLoading] = useState<boolean>(true);
  const updateTotals = ({rest, tempo}: ExerciseCardClass, updatedInputs: CardInputsClass): void => {
    setLoading(true);
    if (!updatedInputs) {
      return;
    }
    if(!inputTypes){
      return;
    }
    const valuesGetter = new ValuesGetter(rest, tempo, updatedInputs, inputTypes!);
    const inputs = valuesGetter.inputValues
    setTotals({
      [Total.RVOL]: 'Reps' in inputs ? CalculateTotal([inputs['Reps']]) + '' : '',
      [Total.LVOL]: 'Reps' in inputs && 'Weight' in inputs ? (CalculateTotal([inputs['Reps'], inputs['Weight']])) + TotalValueUnits[Total.LVOL] : '',
      [Total.TUT]:  'Reps' in inputs ? (CalculateTotal([inputs['Reps']]) * valuesGetter.tempo) + TotalValueUnits[Total.TUT] : '',
      [Total.TIME]: 'Time' in inputs ? CalculateTotal([inputs['Time']]) + TotalValueUnits[Total.TIME] : '',
      [Total.SETS]: valuesGetter.sets + '',
      [Total.REST]: (valuesGetter.rest * (valuesGetter.sets - 1)) + TotalValueUnits[Total.REST],
      [Total.LENGTH]: 'Reps' in inputs ? ((CalculateTotal([inputs['Reps']]) * valuesGetter.tempo) + (valuesGetter.rest * (valuesGetter.sets - 1))) + TotalValueUnits[Total.LENGTH] : '',
      [Total.ONERM]: '',
    });
    setLoading(false);
  };

  return [totals, updateTotals, loading];
};