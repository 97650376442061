import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Typography } from '@mui/material';
import { getSelectedWeekSessions } from '../../../+store/workouts/reducers';
import store from '../../../store/store';
import { WeekConfig } from '../../../tsUtils';

interface Props {
  session: { 
    day: Day,
    week: number,
    config: { sessionId: number; workoutId: number; dateTime: string }
  };
  open: boolean;
  onConfirm: (session: { 
    day: Day,
    week: number,
    config: { sessionId: number; workoutId: number; dateTime: string }
  }) => void;
  onCancel: () => void;
}

function ConfirmDeleteWeek(props: Props) {
  const [open, setOpen] = useState(false);
  const [session, setSession] = useState(props.session);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setSession(props.session);
  }, [props.session]);
  
  function handleConfirm() {
    props.onConfirm(session);
    props.onCancel();
  }
  function handleCancel() {
    props.onCancel();
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent
        sx={{
          width: '350px',
          minHeight: '120px',
          display: 'flex',
          padding: '20px 25px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Typography>
          The prescribed and client input information from this session will be lost.
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button variant="outlined" color="info" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleConfirm}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmDeleteWeek;
