import { PaginationResults } from '../../tsUtils';
import { ExerciseInterface } from '../../tsUtils/exerciseTypes';
import * as paths from './paths';

export async function getExercises() {
  let endpoint = `${paths.COACHEXERCISES}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}

export async function getExerciseSearch(
  query: string,
): Promise<PaginationResults<ExerciseInterface>> {
  let endpoint = `${paths.COACHEXERCISESEARCH}${query}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export async function getExerciseDetails(id: number): Promise<ExerciseInterface> {
  let endpoint = `${paths.COACHEXERCISES}/${id}`;
  let headers = paths.getAuthHeader();
  return await fetch(endpoint, {
    method: 'GET',
    headers,
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
