import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { programActions } from '../../../+store/programs/actionTypes';
// import {getPrograms, getProgramsClient} from '../../services/API';
import '../../../style/Coach/ListPage.scss';
import { RootState } from '../../../store/store';
import BaseDialog from '../dialogs/BaseDialog';
import ProgramInfoForm from '../dialogs/forms/ProgramInfoForm';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { programSelectors } from '../../../+store/programs/reducers';
import { AccountType, ProgramInterface } from '../../../tsUtils';
import { orderBy } from 'lodash';
import UserAvatar from '../../../common/UserAvatar';
import PopupMenu from "../../../common/PopupMenu";
import { DeleteOutlined, MoreVert } from "@mui/icons-material";
const ProgramStyles = {
  programName: {
    cursor: 'pointer',
    '&:hover': {
      color: '#15998E',
    },
  },
  row: {
    cursor: 'default',
  },
};
function Programlist(props: any) {
  const navigate = useNavigate();
  const programs = useSelector((state: RootState) => programSelectors.selectAll(state));
  const selectedProgram = useSelector((state: RootState) => state.programs.selectedProgram)
  const pagination = useSelector((state: RootState) => state.programs.pagination);
  const loading = useSelector((state: RootState) => state.programs.loading);
  const loaded = useSelector((state: RootState) => state.programs.loaded);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ProgramInterface | null>(null);
  const userType = useSelector((state: RootState) => state.auth.user?.accounttype);
  const dispatch = useDispatch();

  function openDialog(row: ProgramInterface) {
    setSelectedRow(row);
    setOpen(true);
  }
  function confirmedDelete() {
    dispatch(programActions.deleteProgram(selectedRow?.id!));
    if (loaded) {
      handleClose();
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  const openEdit = (program: ProgramInterface) => {
    if (userType === AccountType.Client) {
      dispatch(programActions.getClientProgramWorkouts(program.id))
    } else {
      dispatch(programActions.setSelectedProgram(program.id))
    }
  };

  useEffect(() => {
    if (!loading && loaded && selectedProgram && userType !== AccountType.Client) {
      navigate(`/coach/programs/${selectedProgram.id}/0`);
    }
    if (!loading && loaded && selectedProgram && userType === AccountType.Client) {
      navigate(`/client/programs/${selectedProgram.id}`);
    }
    return () => {};
  }, [selectedProgram, loaded]);

  useEffect(() => {
    dispatch(programActions.clearProgram())
  }, [])

  // FOR PAGINATION
  useEffect(() => {
    orderBy(programs, ['modified'], 'asc');
    return () => {};
  }, [programs]);

  const loadMore = () => {
    if (!pagination?.next) return;
    if (userType === AccountType.Client) {
      dispatch(programActions.clientGetPrograms(pagination.next));
    } else {
      dispatch(programActions.getPrograms(pagination.next));
    }
  };

  const deleteItem = (data: ProgramInterface) => openDialog(data);
  const editItem = (data: ProgramInterface) => openEdit(data);

  const options: ('edit' | 'copy' | 'delete')[] = ['delete'];

  return (
    <div className="listPage">
      <div className={userType === AccountType.Client ? 'listgroup-client' : 'listgroup'}>
        {userType !== AccountType.Client && (
          <div className="listActions">
            <BaseDialog buttonWidth={170} buttonSize="large" title="Create New Program" buttonText="New Program">
              <ProgramInfoForm edit={false} />
            </BaseDialog>
          </div>
        )}
        <div className="listDiv">
          <Box color="primary" className="list-header-row">
            <Typography
              component="div"
              className="list-row-item item1"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Program
            </Typography>

            <Typography
              component="div"
              className="list-row-item item2"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              {userType === AccountType.Client ? 'Coach' : 'Clients'}
            </Typography>

            <Typography
              component="div"
              className="list-row-item item3"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Start
            </Typography>

            <Typography
              component="div"
              className="list-row-item item4"
              style={{ lineHeight: 1.1 }}
              variant="button"
            >
              Macro-cycle
            </Typography>
          </Box>
          {loading ? (
            <div className="loading-cont">
              <CircularProgress />
            </div>
          ) : (
            <div className="list-body">
              {programs.length > 0 ? (
                programs.map(program =>
                  userType === AccountType.Client ? (
                    <div key={program.id} onClick={() => editItem(program)} className="list-row">
                      <Typography variant="button" className="list-row-item item1" component="div">
                        {program.name}
                      </Typography>

                      <div className="user-pill-cont item2">
                        <div className="user-pill">
                          <Typography variant="caption" component="span">
                            {program.creator.first_name
                              ? program.creator.first_name + ' ' + program.creator.last_name
                              : program.creator.username}
                          </Typography>
                        </div>
                      </div>

                      <Typography variant="button" className="list-row-item item3" component="div">
                        {new Date(program.start).toLocaleDateString('en-AU', {
                          day: '2-digit',
                          month: 'short',
                          year: '2-digit',
                        })}
                      </Typography>
                      <Typography variant="button" className="list-row-item item4" component="div">
                        {program.macro}
                      </Typography>
                    </div>
                  ) : (
                    <div onClick={() => editItem(program)} key={program.id} className="list-row" >
                      <Typography variant="button" sx={ProgramStyles.programName} className="list-row-item item1"  component="div">
                        {program.name}
                      </Typography>

                      <div className="user-pill-cont item2">
                        {program.client?.map(client => (
                          <UserAvatar
                            key={client.id}
                            last={client.last_name}
                            size={24}
                            borderColor="grey"
                            borderWidth={1}
                            first={client.first_name ?? client.username}
                          />
                        ))}
                      </div>

                      <Typography variant="button" className="list-row-item item3" component="div">
                        {new Date(program.start).toLocaleDateString('en-AU', {
                          day: '2-digit',
                          month: 'short',
                          year: '2-digit',
                        })}
                      </Typography>

                      <Typography variant="button" className="list-row-item item4" component="div">
                        {program.macro}
                      </Typography>

                      
                      <div className="list-row-item item5">
                        <PopupMenu 
                        menuItems={[
                          {text: "Delete", action: () => deleteItem(program), icon: <DeleteOutlined />},
                        ]} 
                        icon={<MoreVert />} 
                        popoverId={`program-list-${program.id}`} 
                        
                        />
                      </div>
                    </div>
                  ),
                )
              ) : (
                <div
                  style={{
                    gridRow: '1/-1',
                    gridColumn: '1/-1',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color="InactiveCaptionText"
                    sx={{ width: '100%' }}
                    component="span"
                    variant="h2"
                  >
                    No Programs Found
                  </Typography>
                </div>
              )}
            </div>

          )}
          {pagination?.next && !loading && (
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '10px 0px', display: 'block', height: '40px' }}
              onClick={loadMore}
            >
              Load More
            </Button>
          )} 
        </div>
        
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete program <strong>{selectedRow?.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={confirmedDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Programlist;
