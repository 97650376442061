import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/Coach/ClientForm.scss';
import * as appActions from '../../../store/app/actions';
import { RootState } from '../../../store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, TextField, CircularProgress } from '@mui/material';
import * as clientActions from '../../../+store/clients/actionTypes';
import { AccountType, User } from '../../../tsUtils';
interface Props {
  client?: User;
  onClose?: () => void;
}
export interface ClientForm {
  first_name: string;
  last_name: string;
  email: string;
  accounttype: AccountType;
}

function CreateClientForm(props: Props) {
  const loading = useSelector((state: RootState) => state.clients.loading);
  const loaded = useSelector((state: RootState) => state.clients.loaded);
  
  const { control, handleSubmit, formState, reset } = useForm<ClientForm>({
    defaultValues: props.client ? {
      first_name: props.client.first_name,
      last_name: props.client.last_name,
      email: props.client.email,
    } : {
      first_name: '',
      last_name: '',
      email: '',
    },
  });

  useEffect(() => {
    if (props.client) {
      reset({
        first_name: props.client.first_name,
        last_name: props.client.last_name,
        email: props.client.email,
      });
    }
  }, [props.client]);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<ClientForm> = data => {
    if(props.client) {
      dispatch(
        clientActions.updateClient({
          ...data,
          accounttype: AccountType.Client,
        }, props.client.id),
      );
    } else {
      dispatch(
        clientActions.createClient({
          ...data,
          accounttype: AccountType.Client,
        }),
      );
    }
    if (loaded) {
      dispatch(appActions.closeDialogs());
    }
  };

  function onCancel() {
    props.onClose && props.onClose();
    dispatch(appActions.closeDialogs());
  }

  return (
    <div className="client-form-cont">
      {loading ? (
        <CircularProgress />
      ) : (
        <form className="client-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="client-form-controls">
            <Controller
              control={control}
              name="first_name"
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  variant="filled"
                  title="First Name"
                  label="First Name"
                  helperText={error ? error.message : ''}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  variant="filled"
                  error={!!error}
                  title="Last Name"
                  label="Last Name"
                  helperText={error ? error.message : ''}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  variant="filled"
                  error={!!error}
                  type="email"
                  title="Email Address"
                  label="Email Address"
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </div>

          <div className="form-buttons">
            <Button onClick={() => onCancel()} variant="outlined" color="info">
              Cancel
            </Button>
            <Button
              // disabled={!isDirty || !isValid}
              type="submit"
              variant="contained"
              color="primary"
            >
              Create Client
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CreateClientForm;
