import React from 'react';
import { TextField } from '@mui/material';

import { styled } from '@mui/system';
export const StyledInput = styled(TextField)`
  & .MuiOutlinedInput-input {
    height: 20px;
    border: none;
    width: 100%;
    background: #eee;
    border-radius: 4px;
    text-align: center;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
    text-align: center;
    background: #eeeeee00;
  }
  & .MuiOutlinedInput-input {
    padding: 6px 8px;
  }
  & .MuiInputLabel-formControl {
    top: -13px;
    line-height: 2.5em;
  }
`;
export const StyledSmallInput = styled(TextField)`
  & .MuiOutlinedInput-input {
    margin: none;
    height: 20px;
    border: none;
    width: 100%;
    background: #eee;
    border-radius: 4px;

    text-align: center;
  }
  & .MuiOutlinedInput-notchedOutline {
    margin: none;
    border: none;
    text-align: center;
    background: #eeeeee00;
  }
  & .MuiInputBase-input {
    padding: 6px 2px;
  }

  & .MuiOutlinedInput-input {
    padding: 6px 2px;
  }
  & .MuiInputLabel-formControl {
    top: -2px;
  }
`;
