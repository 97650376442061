import { PromptQuestions } from '../../coachApp/programs/dialogs/forms/ProgramInfoForm';
import { PostProgramInterface, SessionFeedbackRequest } from '../../tsUtils';

export const actionTypes = {
  // List Program
  GET_PROGRAMS: '[Programs] Get Programs',
  CLIENT_GET_PROGRAMS: '[Programs] Client Get Programs',
  GET_PROGRAMS_SUCCESS: '[Programs] Get Program Success',
  GET_PROGRAMS_FAIL: '[Programs] Get Program Failure',
  // Get Single Program
  GET_PROGRAM_WORKOUTS: '[Programs] Get Program Workouts',
  GET_CLIENT_PROGRAM_WORKOUTS: '[Programs] Get Client Program Workouts',
  GET_PROGRAM_WORKOUTS_SUCCESS: '[Programs] Get Program Workouts Success',
  GET_PROGRAM_WORKOUTS_FAIL: '[Programs] Get Program Workouts Failure',
  // Update Program
  UPDATE_PROGRAM_REQUEST: '[Programs] Update Program Request',
  UPDATE_PROGRAM_DETAILS_REQUEST: '[Programs] Update Program Details Request',
  UPDATE_PROGRAM_SUCCESS: '[Programs] Update Program Success',
  UPDATE_PROGRAM_FAIL: '[Programs] Update Program Failure',

  UPDATE_PROGRAM_MESSAGE_REQUEST: '[Programs] Update Program Message Request',

  CREATE_PROGRAM_REQUEST: '[Programs] Create Program Request',
  CREATE_PROGRAM_SUCCESS: '[Programs] Create Program Success',
  CREATE_PROGRAM_FAIL: '[Programs] Create Program Failure',

  ADD_PROGRAM_WEEK: '[Programs] Add Program Week',
  ADD_PROGRAM_WEEK_FAIL: '[Programs] Add Program Week Failure',

  CLEAR_PROGRAM: '[Programs] Clear Program',
  CLEAR_PROGRAM_SUCCESS: '[Programs] Clear Program Success',
  CLEAR_PROGRAM_FAIL: '[Programs] Clear Program Failure',

  DELETE_PROGRAM_REQUEST: '[Programs] Delete Program Request',
  DELETE_PROGRAM_SUCCESS: '[Programs] Delete Program Success',
  DELETE_PROGRAM_FAIL: '[Programs] Delete Program Fail',

  DELETE_PROGRAM_WEEK_REQUEST: '[Programs] Delete Program Week Request',
  DELETE_PROGRAM_WEEK_SUCCESS: '[Programs] Delete Program Week Success',
  DELETE_PROGRAM_WEEK_FAIL: '[Programs] Delete Program Week Fail',

  UPDATE_CLIENT_INPUTS_REQUEST: '[Client Inputs] Update Client Inputs Request',
  UPDATE_CLIENT_INPUTS_SUCCESS: '[Client Inputs] Update Client Inputs Success',
  UPDATE_CLIENT_INPUTS_FAIL: '[Client Inputs] Update Client Inputs Failure',

  CREATE_TEMPLATE_REQUEST: '[Templates] Create Template Request',
  CREATE_TEMPLATE_SUCCESS: '[Template] Create Template Success',
  CREATE_TEMPLATE_FAIL: '[Template] Create Template Fail',

  GENERATE_PROGRAM_REQUEST: '[AI] Generate Program Request',
  GENERATE_PROGRAM_SUCCESS: '[AI] Generate Program Success',
  GENERATE_PROGRAM_FAIL: '[AI] Generate Program Fail',

  SET_SELECTED_PROGRAM: '[Programs] Set Selcted Program', 
  SET_SELECTED_PROGRAM_SUCCESS: '[Programs] Set Selected Programs Success'
};

export const programActions = {
  getPrograms: (query: string) => {
    return {
      type: actionTypes.GET_PROGRAMS,
      query,
    };
  },
  clientGetPrograms: (query: string) => {
    return {
      type: actionTypes.CLIENT_GET_PROGRAMS,
      query,
    };
  },
  getProgramWorkouts: (id: number) => {
    return {
      type: actionTypes.GET_PROGRAM_WORKOUTS,
      id,
    };
  },
  getClientProgramWorkouts: (id: number) => {
    return {
      type: actionTypes.GET_CLIENT_PROGRAM_WORKOUTS,
      id,
    };
  },
  updateProgram: (program: PostProgramInterface) => {
    return {
      type: actionTypes.UPDATE_PROGRAM_REQUEST,
      program,
    };
  },
  updateProgramDetails: (programId: number) => {
    return {
      type: actionTypes.UPDATE_PROGRAM_DETAILS_REQUEST,
      programId,
    };
  },
  updateClientInputs: (programId: number, feedbackData?: SessionFeedbackRequest) => {
    return {
      type: actionTypes.UPDATE_CLIENT_INPUTS_REQUEST,
      programId,
      feedbackData,
    };
  },
  createProgram: (program: PostProgramInterface) => {
    return {
      type: actionTypes.CREATE_PROGRAM_REQUEST,
      program,
    };
  },
  addProgramWeek: (programId: number) => {
    return {
      type: actionTypes.ADD_PROGRAM_WEEK,
      programId,
    };
  },
  clearProgram: () => {
    return {
      type: actionTypes.CLEAR_PROGRAM,
    };
  },
  deleteProgram: (programId: number) => {
    return {
      type: actionTypes.DELETE_PROGRAM_REQUEST,
      programId,
    };
  },
  deleteProgramWeek: (programId: number, weekIndex: number) => {
    return {
      type: actionTypes.DELETE_PROGRAM_WEEK_REQUEST,
      programId,
      weekIndex,
    };
  },
  updateProgramMessage: (message: string) => {
    return {
      type: actionTypes.UPDATE_PROGRAM_MESSAGE_REQUEST,
      message,
    };
  },
  createTemplate: (name: string, programId: number) => {
    return {
      type: actionTypes.CREATE_TEMPLATE_REQUEST,
      name,
      programId,
    };
  },
  generateProgram: (programDetails: PostProgramInterface & { prompt: PromptQuestions }) => {
    return {
      type: actionTypes.GENERATE_PROGRAM_REQUEST,
      programDetails,
    };
  },
  setSelectedProgram: (programId: number) => {
    return {
      type: actionTypes.SET_SELECTED_PROGRAM,
      programId
    }
  }
};

// Save Program
// Get
// Success
// Fail

// Delete Program
// Get
// Success
// Fail
