import { CardInputsClass, SessionFeedbackRequest } from '../../tsUtils';
import * as paths from './paths';
import { ClientInputsInterface } from '../../tsUtils/clientInputsTypes';

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  const authHeaders = new Headers({
    'Content-Type': 'application/json',
    Authorization: `token ${token}`,
  });
  return authHeaders;
};
export async function updateClientInputs(data: {
  inputs: ClientInputsInterface[];
  feedbackData?: SessionFeedbackRequest;
}): Promise<{ message: string }> {
  let endpoint = `${paths.CLIENTINPUTS}`;
  let headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
export function updateClientInputsSingle(data: {
  id: number;
  inputs: CardInputsClass;
  comment: string;
}): Promise<Response> {
  let endpoint = `${paths.CLIENTINPUTS}${data.id}`;
  let headers = getAuthHeader();
  return fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ inputs: data.inputs, comment: data.comment }),
  })
}
export async function updateSessionFeedback(data: SessionFeedbackRequest): Promise<string> {
  let endpoint = `${paths.CLIENTFEEDBACK}`;
  const headers = getAuthHeader();
  return await fetch(endpoint, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(
    response => {
      if (response.ok) {
        return response.json();
      }
    },
    error => {
      return { error };
    },
  );
}
